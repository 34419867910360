import { BreadcrumbConsip } from "../../components/Breadcrumb";
import ProgressBar from "react-bootstrap/ProgressBar";
//import './style.css';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IndexCard, IndexCard2, IndexCardBottom } from "../Monitoring/subpages/DigitalizationIndexes/IndexCard";
import { BarCard } from "./BarCard";
import { getTipologiaBudget, getEcosistemBudget, getDigIndexes } from "../../redux/actions/projectManagement";
import { getAdmin } from "../../redux/actions/core";

export default function Observatory() {
    let history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const superAdmin = useSelector((state) => state.core.superAdmin);
    const pm = useSelector((state) => state.projectManagement);
    const defaultfilter = {};

    useEffect(() => {
        if (user.user?.sub) dispatch(getAdmin(user.user?.sub));
        else history.push("/");
    }, []);

    useEffect(() => {
        dispatch(getTipologiaBudget(user.user?.sub));
        dispatch(getEcosistemBudget(user.user?.sub))
        dispatch(getDigIndexes(defaultfilter))
    }, [])

    return (
        <>
            <div className="container">
                <BreadcrumbConsip
                    pages={[{ label: "Osservatorio", url: "/observatory" }]}
                />
            </div>
            {/* <Alert color="warning">accessToken: {user.accessToken}</Alert>
         <Alert color="warning">idToken: {user.idToken}</Alert> */}
            <div className="info-gradient pb-5">

                <div className="container" style={{ paddingBottom: "12vh" }}>

                    <div className="component-holder">
                        <div className="row text-left">
                            <div className="col-12 col-md-5">
                                <h4>Budget</h4>
                            </div>
                        </div>
                        {/* <div className="title_description">
                            <p>Title Description</p>
                        </div> */}
                        <br></br>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-12 col-md-1"> </div>
                        <div className="col-12 col-md-10">
                            <BarCard
                                cardClass="card-bg rounded"
                                title={"Spesa per ecosistema[k €]"}
                                dataBar={pm?.ecosistem}
                                url={"/monitoring"}
                            />
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="col-12 col-md-1"> </div>
                        <div className="col-12 col-md-10">
                            <BarCard
                                cardClass="card-bg rounded"
                                title="Spesa per tipologia di servizio [k €]"
                                dataBar={pm?.tipologia}
                                url={"/monitoring"}
                            />
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="component-holder">
                        <div className="row text-left">
                            <div className="col-12 col-md-5" style={{ color: "white" }}>
                                <h4>Indicatori di digilitazzione</h4>
                            </div>
                        </div>
                        {/* <div className="title_description" style={{color: "white"}}>
                            <p>Title Description</p>
                        </div> */}
                    </div>
                    <br></br>
                    {/* indexes */}
                    {pm?.indexes?.response[0] !== undefined && (
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-12 col-md-5">
                                <IndexCard
                                    cardClass="card-bg rounded"
                                    title={"Indicatori quantitativi"}
                                    data1={pm?.indexes?.response[0]?.riduzione_spesa + "%"}
                                    data2={pm?.indexes?.response[0]?.riduzione_tempi + "%"}
                                    data3={pm?.indexes?.response[0]?.numero_servizi_aggiuntivi}
                                    caption1="Riduzione % della spesa per l’erogazione del servizio"
                                    caption2="Riduzione % dei tempi di erogazione del servizio"
                                    caption3="Numero servizi aggiuntivi offerti all’utenza interna, esterna (cittadini), esterna (imprese), altre PA"
                                    updateDate="23/5/2022"
                                />
                            </div>
                            <br></br>
                            <br></br>
                            {/* <div className="col-md-1"></div> */}
                            <div className="col-12 col-md-5">
                                <IndexCard
                                    cardClass="card-bg rounded"
                                    title={"Indicatori qualitativi"}
                                    data1={pm?.indexes?.response[0]?.obiettivi_cad}
                                    data2={pm?.indexes?.response[0]?.infrastrutture_immateriali + "%"}
                                    data3={pm?.indexes?.response[0]?.integrazioni_base_dati}
                                    caption1="Obiettivi CAD raggiunti con l’intervento"
                                    caption2="Infrastrutture immateriali integrate"
                                    caption3="Integrazione con Basi Dati di interesse nazionale"
                                    updateDate="23/5/2022"
                                />
                            </div>
                        </div>
                    )}
                    <br></br>
                    <br></br>
                    {pm?.indexes?.response[0] !== undefined && (
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-12 col-md-10">
                                <IndexCard2
                                    cardClass="card-bg rounded"
                                    title={"Indicatori di collaborazione e riuso"}
                                    data1={pm?.indexes?.response[0]?.riuso_processi + "%"}
                                    data2={pm?.indexes?.response[0]?.riuso_soluzioni}
                                    data3={pm?.indexes?.response[0]?.collaborazioni + "%"}
                                    caption1="Riuso di processi per erogazione servizi digitali"
                                    caption2="Riuso soluzioni tecniche"
                                    caption3="Collaborazione con altre Amministrazioni (progetto in comune a più Amministrazioni)"
                                    updateDate="23/5/2022"
                                />
                            </div>
                        </div>
                    )}
                    <br></br>
                    <br></br>
                    {pm?.indexes?.response[0] !== undefined && (
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-12 col-md-10">
                                <IndexCardBottom
                                    cardClass="card-bg rounded"
                                    title={"Indicatori di progresso"}
                                    data1={pm?.indexes?.response[0]?.absc_1 + "%"}
                                    data2={pm?.indexes?.response[0]?.absc_2}
                                    data3={pm?.indexes?.response[0]?.absc_3 + "%"}
                                    data4={pm?.indexes?.response[0]?.absc_4}
                                    data5={pm?.indexes?.response[0]?.absc_5 + "%"}
                                    data6={pm?.indexes?.response[0]?.absc_8}
                                    data7={pm?.indexes?.response[0]?.absc_10 + "%"}
                                    data8={pm?.indexes?.response[0]?.absc_13}
                                    caption1="ABSC 1 (CSC 1): INVENTARIO DEI DISPOSITIVI AUTORIZZATI E NON AUTORIZZATI                                "
                                    caption2="ABSC 2 (CSC 2): INVENTARIO DEI SOFTWARE AUTORIZZATI E NON AUTORIZZATI"
                                    caption3="ABSC 3 (CSC 3): PROTEGGERE LE CONFIGURAZIONI DI HARDWARE E SOFTWARE SUI DISPOSITIVI MOBILI, LAPTOP, WORKSTATION E SERVER"
                                    caption4="ABSC 4 (CSC 4): VALUTAZIONE E CORREZIONE CONTINUA DELLA VULNERABILITÀ"
                                    caption5="ABSC 5 (CSC 5): USO APPROPRIATO DEI PRIVILEGI DI AMMINISTRATORE"
                                    caption6="ABSC 8 (CSC 8): DIFESE CONTRO I MALWARE"
                                    caption7="ABSC 10 (CSC 10): COPIE DI SICUREZZA"
                                    caption8="ABSC 13 (CSC 13): PROTEZIONE DEI DATI"
                                    updateDate="23/5/2022"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}