import React from "react";
import { AccordionConsip } from "../../components/AccordionConsip";
import { BreadcrumbConsip } from "../../components/Breadcrumb";

const data = [
  {
    id: 0,
    title: "Come si aderisce all’Accordo Quadro",
    bodyStart:
      " È possibile consultare il processo di adesione al Contratto Quadro alla sezione dedicata del Portale della Fornitura “Come Aderire”.",
    bodyBulletPoint: [],
    bodyEnd: "",
    links: [],
  },
  {
    id: 1,
    title: "Quali sono i servizi erogati nell’ambito dell’Accordo Quadro?",
    bodyStart: "I servizi oggetto dell’Accordo Quadro sono:",
    bodyBulletPoint: [
      "Security Strategy (L2.S16)",
      "Vulnerability Assessment (L2.S17)",
      "Testing del codice – Statico (L2.S18)",
      "Testing del codice – Dinamico (L2.S19)",
      "Testing del codice – Mobile (L2.S20)",
      "Supporto all’analisi e gestione degli incidenti (L2.S21)",
      "Penetration Testing (L2.S22)",
      "Compliance normativa (L2.S23)",
    ],
    bodyEnd:
      "È possibile approfondire i servizi di fornitura alla sezione dedicata del Portale della Fornitura “Servizi”.",
    links: [],
  },

  {
    id: 2,
    title: "Qual è la durata dei servizi acquistati?",
    bodyStart:
      "Ciascun Contratto Esecutivo avrà una durata massima di 48 mesi dalla data di stipula dello stesso.",
    bodyBulletPoint: [],
    bodyEnd: "",
    links: [],
  },
  {
    id: 3,
    title:
      "Dov’è possibile reperire la documentazione di dettaglio sul Contratto Quadro?",
    bodyStart:
      "La documentazione è reperibile nella sezione del Portale “Accordo Quadro”.",
    bodyBulletPoint: [],
    bodyEnd: "",
    links: [],
  },
  {
    id: 4,
    title: "È disponibile un modello di Contratto Esecutivo?",
    bodyStart:
      "Si. Il modello è disponibile alla sezione del Portale “Accordo Quadro”.",
    bodyBulletPoint: [],
    bodyEnd: "",
    links: [],
  },
  {
    id: 5,
    title: "Come sono calcolati i costi dei servizi?",
    bodyStart:
      "I corrispettivi dovuti al Fornitore per i servizi prestati in esecuzione dei singoli Contratti Esecutivi sono determinati in ragione dei prezzi unitari stabiliti nell’Accordo Quadro, da intendersi validi sino ad eventuali adeguamenti e modifiche successive. Ogni aggiornamento dei costi sostituisce ed annulla i precedenti prezzi unitari.",
    bodyBulletPoint: [],
    bodyEnd: "",
    links: [],
  },
];

export default function FAQPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip
          pages={[{ label: "Domande Frequenti", url: "/faq" }]}
        />
      </div>
      <div className="pb-5">
        <div className="container" style={{ paddingBottom: "20.7vh" }}>
          <div className="text-left pb-3">
            <h3>Domande Frequenti</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left">
              <AccordionConsip data={data}></AccordionConsip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
