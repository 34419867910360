import React from "react";
// import "../../../assets/scss/components/app-loader.scss"

const ComponentSpinner = (props) => {
  // const style = props.color ? { borderColor: props.color } : {};
  if (props.loading) {
    return (
      <div
        className={`component-spinner ${
          props?.block ? "component-spinner--block" : ""
        }`}
      >
        <div
          className="spinner-border"
          role="status"
          style={{
            position: "absolute",
            left: "calc(50% - 27px)",
            top: "calc(50% - 27px)",
          }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return null;
};

export default ComponentSpinner;
