import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPublicDocuments } from "../../redux/actions/documents";

export default function GuidePage() {
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.documents);
  const commonDocuments = documents.publicDocuments;

  useEffect(() => {
    dispatch(getPublicDocuments());
  }, []);

  return (
    <>
      {commonDocuments?.length > 0 ? (
        <div>
          <h6>Documentazione Accordo Quadro</h6>
          <p>
            Di seguito è possibile scaricare la documentazione di dettaglio
            relativa all’Accordo Quadro
          </p>
          <ul>
            {commonDocuments.map((elem, index) => (
              <li key={index}>
                <a href={elem.url} target="_blank" rel="noopener noreferrer">
                  {elem.fileName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        "Nessun documento disponibile"
      )}
    </>
  );
}
