import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivateCommonDocuments } from "../../redux/actions/documents";

export default function DocumentsPage() {
  const dispatch = useDispatch();

  const documents = useSelector((state) => state.documents);
  const commonDocuments = documents.privateCommonDocuments;

  useEffect(() => {
    dispatch(getPrivateCommonDocuments("Documents"));
  }, []);
  return (
    <>
      <div className="pb-5" style={{ minHeight: "60vh" }}>
        <div className="container">
          <div className="text-left pb-3">
            <h3>Documentazione normativa, tecnologica e operativa</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left pb-3">
              <ul>
                {commonDocuments?.length > 0
                  ? commonDocuments.map((elem, index) => (
                      <li key={index}>
                        <a
                          href={elem.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {elem.fileName}
                        </a>
                      </li>
                    ))
                  : "Nessun documento disponibile"}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
