import React from "react";
import Pagination from "react-bootstrap/Pagination";
import "./Pagination.css";

export default function PaginationConsip({
  totalPage = 1,
  currentPage = 1,
  setPage = undefined,
}) {
  const pageItems = (currentPage, total, value) => {
    let arr = [];
    switch (value) {
      case "minor":
        if (total >= 3) arr = [1, 2, 3];
        else if (total === 2) arr = [1, 2];
        else arr = [1];

        return (
          <>
            {arr.map((elem, index) => (
              <Pagination.Item
                style={
                  currentPage === elem
                    ? {
                        boxShadow: "none",
                        backgroundColor: "#06c",
                      }
                    : { boxShadow: "none" }
                }
                key={index}
                active={currentPage === elem}
                onClick={() => setPage(elem)}
              >
                <div
                  style={
                    currentPage === elem
                      ? {
                          boxShadow: "none",
                          color: "white",
                        }
                      : { boxShadow: "none", color: "#06c" }
                  }
                >
                  {elem}
                </div>
              </Pagination.Item>
            ))}
          </>
        );
      case "midle":
        arr = [currentPage - 1, currentPage, currentPage + 1];
        return (
          <>
            {arr.map((elem, index) => (
              <Pagination.Item
                style={
                  currentPage === elem
                    ? {
                        boxShadow: "none",
                        backgroundColor: "#06c",
                        color: "white",
                      }
                    : { boxShadow: "none" }
                }
                key={index}
                active={currentPage === elem}
                onClick={() => setPage(elem)}
              >
                <div
                  style={
                    currentPage === elem
                      ? {
                          boxShadow: "none",
                          color: "white",
                        }
                      : { boxShadow: "none", color: "#06c" }
                  }
                >
                  {elem}
                </div>{" "}
              </Pagination.Item>
            ))}
          </>
        );
      case "greater":
        arr = [total - 2, total - 1, total];
        return (
          <>
            {arr.map((elem, index) => (
              <Pagination.Item
                style={
                  currentPage === elem
                    ? {
                        boxShadow: "none",
                        backgroundColor: "#06c",
                        color: "white",
                      }
                    : { boxShadow: "none" }
                }
                key={index}
                active={currentPage === elem}
                onClick={() => setPage(elem)}
              >
                <div
                  style={
                    currentPage === elem
                      ? {
                          boxShadow: "none",
                          color: "white",
                        }
                      : { boxShadow: "none", color: "#06c" }
                  }
                >
                  {elem}
                </div>{" "}
              </Pagination.Item>
            ))}
          </>
        );
      default:
        return "";
    }
  };
  return (
    <Pagination>
      {currentPage !== 1 && (
        <>
          <Pagination.First onClick={() => setPage(1)} />
          <Pagination.Prev onClick={() => setPage(currentPage - 1)} />
        </>
      )}
      {currentPage >= 3 && totalPage > 3 && (
        <>
          <Pagination.Item
            style={{ boxShadow: "none" }}
            onClick={() => setPage(1)}
          >
            <div style={{ boxShadow: "none", color: "#06c" }}>{1}</div>{" "}
          </Pagination.Item>
          {currentPage !== 3 && <Pagination.Ellipsis />}
        </>
      )}

      {currentPage < 3 || (currentPage === 3 && totalPage === 3)
        ? pageItems(currentPage, totalPage, "minor")
        : currentPage + 3 > totalPage
        ? pageItems(currentPage, totalPage, "greater")
        : pageItems(currentPage, totalPage, "midle")}

      {currentPage + 3 <= totalPage && (
        <>
          {totalPage !== 4 && <Pagination.Ellipsis />}
          <Pagination.Item
            style={{ boxShadow: "none" }}
            onClick={() => setPage(totalPage)}
          >
            <div style={{ boxShadow: "none", color: "#06c" }}>{totalPage} </div>
          </Pagination.Item>
        </>
      )}
      {currentPage !== totalPage && (
        <>
          <Pagination.Next onClick={() => setPage(currentPage + 1)} />
          <Pagination.Last onClick={() => setPage(totalPage)} />
        </>
      )}
    </Pagination>
  );
}
