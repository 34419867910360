import {
  apiCurrentSession,
  apiGetAdmin,
  apiGetAQNumbers,
  //apiGetHomePageInitiatives,
  apiGetNews,
  apiGetSection,
  apiGetSingleNews,
} from "../../services/apiRequests";

import history from "../../history";

export const getNews = (last = undefined) => {
  //console.log("ACTION | getNews");
  return (dispatch) => {
    //console.log("ACTION | getNews -> inside action");
    apiGetNews(last)
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          //console.log("ACTION | getNews ERROR", error);
          return [];
        }
      })
      .then((response) => {
        //console.log("ACTION | getNews().response:", response);
        dispatch({ type: "SET_HOMEPAGE_NEWS", payload: response });
      });
  };
};

export const getSingleNews = (idNews = undefined) => {
  //console.log("ACTION | getSingleNews");
  return (dispatch) => {
    //console.log("ACTION | getSingleNews -> inside action");
    apiGetSingleNews(idNews)
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          //console.log("ACTION | getSingleNews ERROR", error);
          return [];
        }
      })
      .then((response) => {
        //console.log("ACTION | getSingleNews().response:", response);
        let value = response.length === 1 ? response[0] : undefined;
        dispatch({ type: "SET_SINGLE_NEWS", payload: value });
      });
  };
};

export const getHomePageAQNumbers = () => {
  //console.log("ACTION | getHomePageAQNumbers");
  return (dispatch) => {
    apiGetAQNumbers()
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          //console.log("ACTION | getHomePageAQNumbers ERROR:", error);
          return {
            total: 0,
            inProgress: 0,
            finished: 0,
          };
        }
      })
      .then((response) => {
        //console.log("ACTION | getHomePageAQNumbers.response:", response);
        dispatch({ type: "SET_HOMEPAGE_AQNUMBERS", payload: response });
      });
  };
};

export const getSections = () => {
  //console.log("ACTION | getSections");

  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });

    apiCurrentSession()
      .then((response) => {
        //console.log("CHHECK SESSION | session:", response);
        let token = response.getAccessToken().getJwtToken();
        return token;
      })
      .then((token) => {
        apiGetSection(token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              //console.log("ACTION | getSections ERROR:", error);
              return [];
            }
          })
          .then((response) => {
            //console.log("ACTION | getSections.response:", response);
            dispatch({ type: "SET_SECTIONS", payload: response });
            dispatch({ type: "SET_ADMIN", payload: true });
            dispatch({ type: "SET_LOADING", payload: false });
          });
      });
  };
};

export const getAdmin = (userId) => {
  //console.log("ACTION | getAdmin");
  //console.log("ACTION | getAdmin().userId:", userId);

  return (dispatch) => {
    //console.log("ACTION | getAdmin -> inside action");
    dispatch({ type: "SET_LOADING", payload: true });

    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();
        return token;
      })
      .then((token) => {
        apiGetAdmin(userId, token)
          .then((response) => {
            try {
              return response?.text()?.then(function (text) {
                if (text === "SUPER ADMIN") return true;
                else return undefined;
              });
            } catch (error) {
              return undefined;
            }
          })
          .then((responseValue) => {
            if (responseValue) {
              dispatch(getSections());
            } else if (!responseValue) {
              history.push("/");
              dispatch({ type: "SET_ADMIN", payload: responseValue });
              dispatch({ type: "SET_LOADING", payload: false });
            }
          });
      });
  };
};

/*export const getHomePageInitiatives = () => {
  //console.log("ACTION | getHomePageInitiatives");
  return (dispatch) => {
    apiGetHomePageInitiatives()
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          //console.log("ACTION | getHomePageInitiatives ERROR:", error);
          return [];
        }
      })
      .then((response) => {
        //console.log("ACTION | getHomePageInitiatives.response:", response);
        dispatch({ type: "SET_HOMEPAGE_INITIATIVES", payload: response });
      });
  };
};
*/
