import React, { useState } from "react";
import { CookieBar, CookieBarButtons, CookieBarButton } from "design-react-kit";

export const CookieBarComponent = ({ open }) => {
  const [show, setShow] = useState(open);
  const toggleVisibility = (show) => setShow(!show);
  if (!show) return null;
  else {
    return (
      <CookieBar>
        <p>
          Questo sito utilizza cookie tecnici, analytics e di terze parti.{" "}
          <br />
          Proseguendo nella navigazione accetti l’utilizzo dei cookie.
        </p>
        <CookieBarButtons className="col align-self-center mt-3 mt-md-0">
          <CookieBarButton onClick={toggleVisibility}>
            Accetto<span className="sr-only"> i cookies</span>
          </CookieBarButton>
        </CookieBarButtons>
      </CookieBar>
    );
  }
};
