import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import ProgressBar from "react-bootstrap/ProgressBar";
import { MonitoringCard, MonitoringCardBottom } from "./MonitoringCard";
import { useHistory } from "react-router-dom";
import { getFilters, getMonitoringDetails } from "../../redux/actions/projectManagement";
import { getUserEntity } from "../../redux/actions/user";


export default function Monitoring() {
    let history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const pm = useSelector((state) => state.projectManagement);
    let firstRender = true;

    useEffect(() => {
        dispatch(getFilters())
        dispatch(getMonitoringDetails(filters))
        firstRender = false;
    }, [firstRender = true]);

    //filters
    const [enteType, setEnteType] = useState([]);
    const [enteTypeId, setEnteTypeId] = useState('');
    const [ente, setEnte] = useState([]);
    const [enteId, setEnteId] = useState('');
    const [contract, setContract] = useState([]);
    const [contractId, setContractId] = useState('');
    const [status, setStatus] = useState([]);
    const [statusId, setStatusId] = useState('');
    const [filters, setFilters] = useState({});

    const applyFilters = () => {

        let filtersnew = {}

        if (enteTypeId) {
            filtersnew.enteType = parseInt(enteTypeId)
        }
        if (enteId) {
            filtersnew.enti = parseInt(enteId)
        }
        if (contractId) {
            filtersnew.contrattiEsecutivi = parseInt(contractId)
        }
        if (statusId) {
            filtersnew.iniziative = parseInt(statusId)
        }
        setFilters(filtersnew)
        dispatch(getMonitoringDetails(filtersnew))

    };


    useEffect(() => {
        const getEnteType = async () => {
            setEnteType(await pm?.filters?.filterArray?.allFilters);
        }
        getEnteType();
    }, []);


    useEffect(() => {
        const getEnte = async () => {
            enteType.map((i) => {
                if (i.ente_type_id == enteTypeId) {
                    setEnte(i.Entis);
                }
            })
            if (!enteTypeId) {
                setEnte([]);
            }
        }
        setEnte([]);
        setEnteId('');
        getEnte();
        document.getElementById("ente_select").selectedIndex = 0;
        setContract([]);
        setContractId('');
    }, [enteTypeId]);


    useEffect(() => {
        const getContract = async () => {
            ente.map((i) => {
                if (i.id_ente == enteId) {
                    setContract(i.ContrattiEsecutivis);
                }
            })
        }
        getContract();
        setContractId('');
        document.getElementById("contract_select").selectedIndex = 0;
    }, [enteId]);


    useEffect(() => {
        const getStatus = async () => {
            const resStatus = await pm?.filters?.statusArray
            setStatus(resStatus);
        }
        getStatus();
    }, []);

    return (
        <>
            <div className="container">
                <BreadcrumbConsip
                    pages={[{ label: "Monitoraggio", url: "/monitoring" }]}
                />
            </div>
            {/* <Alert color="warning">accessToken: {user.accessToken}</Alert>
                <Alert color="warning">idToken: {user.idToken}</Alert> */}

            <div className="info-gradient pb-5">
                <div className="container" style={{ paddingBottom: "12vh" }}>

                    {/* Filters */}
                    {status !== undefined && enteType !== undefined && (

                        <form id="myForm">
                            <div className="row g-3">
                                <div className="col-md-4" style={{ textAlign: "left" }}>
                                    <h4>Reportistica </h4>
                                    {/* <div className="title_description">
                                        <p>Title Description</p>
                                    </div> */}
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">Tipologia Ente</label>
                                    <select name="tipologia_ente" className="form-control p-2" onChange={(e) => setEnteTypeId(e.target.value)}>
                                        <option value=""></option>
                                        {
                                            enteType.map((resEnteType, index) => (
                                                <option key={index} value={resEnteType.ente_type_id}>{resEnteType.ente_type_name} </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">Ente</label>
                                    <select id="ente_select" className="form-control p-2" name="ente" onChange={(e) => setEnteId(e.target.value)}>
                                        <option selected value=""></option>
                                        {
                                            ente.map((test, index) => (
                                                <option key={index} value={test.id_ente}>{test.ente_name} </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">Contratto Esecutivo</label>
                                    <select id="contract_select" className="form-control p-2" name="contratto" onChange={(e) => setContractId(e.target.value)}>
                                        <option selected value=""></option>
                                        {
                                            contract.map((test2, index) => (
                                                <option key={index} value={test2.id_contratto}> {test2.contratto_name} </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-2"   >
                                    <label className="form-label">Stato Contratto Esecutivo</label>
                                    <select className="form-control p-2" name="status" onChange={(e) => setStatusId(e.target.value)}>
                                        <option value=""></option>
                                        {
                                            status.map((test3, index) => (
                                                <option key={index} value={test3[0]}> {test3[1]} </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div style={{ textAlign: "right" }}>
                                    <button type="button" className="btn btn-primary mt-1"
                                        style={{ paddingRight: "1vw", paddingLeft: "1vw" }} onClick={applyFilters}>Applica Filtri</button>
                                </div>
                            </div>
                        </form>
                    )}
                    <br></br>
                    <div className="row my-3 px-2">
                        <div
                            className="col-12"
                            style={{
                                background: "#d4d4d4",
                                borderRadius: "15px",
                            }}>
                            <div className="row">
                                <div className="col-6 align-self-center">
                                    <div
                                        className="row align-items-center"
                                        style={{ color: "black" }}
                                    >
                                        <div className="col-6" style={{ fontSize: "20px" }}>
                                            <b>
                                                Totale budget
                                            </b>
                                        </div>
                                        <div className="col-6 text-center" style={{ fontSize: "26px" }}>
                                            <b>{pm?.monitoring_details?.totaleBudget}</b>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-6 text-left control py-3"
                                    style={{ color: "white", paddingRight: "3vw" }}
                                >
                                    {/* These progress bars are inverted. Eg. we use 20% as 100 - 20% = 80% */}

                                    <ProgressBar

                                        style={{
                                            borderRadius: "15px",
                                            height: "30px",
                                            border: "4px solid white",
                                        }}
                                        min={0}
                                        max={pm?.monitoring_details?.response?.totalBudget}
                                        now={pm?.monitoring_details?.response?.totalBudget}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 align-self-center">
                                    <div
                                        className="row align-items-center"
                                        style={{ color: "black" }}
                                    >
                                        <div className="col-6" style={{ fontSize: "20px" }}>
                                            <b>
                                                Valore Contratti Esecutivi
                                            </b>
                                        </div>
                                        <div className="col-6 text-center" style={{ fontSize: "26px" }}>
                                            <b>{pm?.monitoring_details?.valoreContrattiEsecutivi}</b>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-6 text-left control py-3"
                                    style={{ color: "white", paddingRight: "3vw" }}
                                >
                                    <ProgressBar

                                        style={{
                                            borderRadius: "15px",
                                            height: "30px",
                                            border: "4px solid white",
                                        }}
                                        min={0}
                                        max={pm?.monitoring_details?.response?.totalBudget}
                                        now={pm?.monitoring_details?.response?.totaleContrattiEsecutivi}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 align-self-center">
                                    <div
                                        className="row align-items-center"
                                        style={{ color: "black" }}
                                    >
                                        <div className="col-6" style={{ fontSize: "20px" }}>
                                            <b>
                                                Valore consumato
                                            </b>
                                        </div>
                                        <div className="col-6 text-center" style={{ fontSize: "26px" }}>
                                            <b>{pm?.monitoring_details?.valoreConsumato}</b>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-6 text-left control py-3"
                                    style={{ color: "white", paddingRight: "3vw" }}
                                >
                                    <ProgressBar

                                        style={{
                                            borderRadius: "15px",
                                            height: "30px",
                                            border: "4px solid white",

                                        }}
                                        min={0}
                                        max={pm?.monitoring_details?.response?.totalBudget}
                                        now={pm?.monitoring_details?.response?.valoreConsumato}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div
                                        className="row align-items-center"
                                        style={{ color: "black" }}
                                    >
                                        <div className="col-6">
                                            <b style={{ fontSize: "38px" }}>
                                                <img
                                                    alt="icon"
                                                    className="p-3"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/icons/SLA-tableIcons/Picture6.png"
                                                    }
                                                />
                                                {pm?.monitoring_details?.response?.contrattiEsecutiviAvviati}
                                            </b>
                                        </div>
                                        <div className="col-6 text-left">
                                            <b>Contratti Esecutivi Avviati</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div
                                        className="row align-items-center"
                                        style={{ color: "black" }}
                                    >
                                        <div className="col-6" style={{ fontSize: "24px" }}>
                                            <b style={{ fontSize: "38px" }}>
                                                <img
                                                    alt="icon"
                                                    className="p-3"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/icons/SLA-tableIcons/Picture8.png"
                                                    }
                                                />
                                                {pm?.monitoring_details?.response?.contrattiEsecutiviConclusi}
                                            </b>
                                        </div>
                                        <div className="col-6text-left">
                                            <b>Contratti Esecutivi Conclusi</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" >
                                <div
                                    className="row align-items-center"
                                    style={{ color: "black" }}
                                >
                                    <div className="col-6" style={{ fontSize: "24px", paddingLeft: "13vw" }}>
                                        <b style={{ fontSize: "38px" }}>
                                            <img
                                                alt="icon"
                                                className="p-3"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/icons/SLA-tableIcons/Picture9.png"
                                                }
                                            />
                                            {pm?.monitoring_details?.response?.contrattiEsecutiviInFaseDiAvvio}
                                        </b>
                                    </div>
                                    <div className="col-6text-left" style={{ paddingRight: "4vw" }}>
                                        <b>Contratti Esecutivi in fase di avvio</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <MonitoringCardBottom
                                cardClass="card-bg rounded"
                                title={"Indici di qualità"}
                                caption=""
                                icon={
                                    process.env.PUBLIC_URL +
                                    "/assets/icons/info-soluzioni-migliorative.svg"
                                }
                                url={"/monitoring/sla"}
                            />
                        </div>
                        
                        {/* <div className="col-12 col-md-4 ">
                            <MonitoringCardBottom
                                cardClass="card-bg rounded"
                                title={"Customer Satisfaction e qualità delle informazioni"}
                                caption="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
                                icon={
                                    process.env.PUBLIC_URL +
                                    "/assets/icons/info-modelli-operativi.svg"
                                }
                                url={"/monitoring/customer-satisfaction"}

                            />
                        </div> */}
                       
                        <div className="col-12 col-md-6">
                            <MonitoringCardBottom
                                cardClass="card-bg rounded"
                                title="Indicatori di digitalizzazione"
                                caption=""
                                icon={
                                    process.env.PUBLIC_URL +
                                    "/assets/icons/info-guida-alla-stima.svg"
                                }
                                url={"/monitoring/digitalization-indexes"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}




