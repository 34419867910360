/* eslint-disable default-case */
import produce from "immer";

const initialState = {
  loading: false,
  user: null,
  entity: [],
  token: null,
  accessToken: null,
  idToken: null,
  selectedEntity: undefined,
  selectedEntityName: "",
};

const userReducer = produce((draft, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      draft.user = action.payload;
      break;
    case "SET_USER_ENTITY":
      draft.entity = action.payload;
      draft.selectedEntity = action.ente;
      draft.selectedEntityName = action.name;
      break;
    case "SET_USER_TOKEN":
      draft.token = action.payload;
      break;
    case "SET_USER_TOKENS":
      draft.accessToken = action.payload.accessToken;
      draft.idToken = action.payload.idToken;
      break;
    case "SET_CUSTOMER_LOADING":
      draft.loading = action.payload;
      break;
    case "SET_SELECTED_ENTITY":
      draft.selectedEntity = action.ente;
      draft.selectedEntityName = action.name;
      break;
    case "SET_USER_LOGOUT":
      return initialState;
  }
}, initialState);

export default userReducer;
