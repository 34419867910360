import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export const BreadcrumbConsip = ({ pages }) => {
  //console.log("Breadcrumbs.js, pages:", pages);
  return (
    <section>
      <nav className="breadcrumb-container">
        <Breadcrumb aria-label="breadcrumb" listTag="ol" tag="nav">
          <BreadcrumbItem tag="li">
            <Link to="/home">Home</Link>
          </BreadcrumbItem>
          {pages.map((item, index) => (
            <BreadcrumbItem
              key={index}
              active={pages.length === index + 1}
              tag="li"
            >
              <span className="separator">/</span>
              <Link to={item.url}>{item.label}</Link>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </nav>
    </section>
  );
};
