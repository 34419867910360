const initialState = {
  contractDetails: undefined,
  contractDetailsSpecial: undefined,
  privateCommonDocuments: [],
  privateGuida: undefined,
  privateModels: undefined,
  privateSolution: undefined,
  publicDocuments: [],
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONTRACT_DETAILS":
      return {
        ...state,
        contractDetails: action.payload,
        contractDetailsSpecial: action.sp,
      };
    case "SET_PUBLIC_DOCUMENTS":
      return {
        ...state,
        publicDocuments: action.payload,
      };
    case "SET_PRIVATE_COMMON_DOCUMENTS":
      switch (action.doc) {
        case "Documents":
          return {
            ...state,
            privateCommonDocuments: action.payload,
          };
        case "Guida":
          return {
            ...state,
            privateGuida: action.payload[0].url,
          };
        case "Models":
          return {
            ...state,
            privateModels: action.payload[0].url,
          };
        case "Solution":
          return {
            ...state,
            privateSolution: action.payload[0].url,
          };
        default: {
          return {
            ...state,
            privateCommonDocuments: [],
            privateGuida: undefined,
            privateModels: undefined,
            privateSolution: undefined,
          };
        }
      }
    case "SET_PUBBLIC_COMMON_DOCUMENTS":
      return {
        ...state,
        publicCommonDocuments: action.payload,
      };
    default:
      return state;
  }
};

export default documentsReducer;
