import React, { Suspense, lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";
// import { ContextLayout } from "./utility/context/Layout";
import { connect } from "react-redux";
// import { useSearch } from "utility/search";
import HomePage from "./pages/HomePage/HomePage";
import InfoPage from "./pages/InfoPage/InfoPage";
import LoginPage from "./pages/Login/LoginPage";
import AuthenticationPage from "./pages/Login/AuthenticationPage";
import Projects from "./pages/ProjectManagement/Projects";
import HowToJoinPage from "./pages/JoinPage/JoinPage";
import ServicePage from "./pages/ServicesPage/ServicesPage";
import FAQPage from "./pages/FAQPage/FAQPage";
import AQPage from "./pages/AQPage/AQPage";
import AdminSection from "./pages/AdminSections/AdminSection";
import UsPage from "./pages/UsPage/UsPage";
import ExecutivesPage from "./pages/ExecutivesPage/ExecutivesPage";
import ExecutivesDocumentsPage from "./pages/ExecutivesPage/ExecutivesDocumentsPage";
import DefaultLayout from "./layouts/basic";
import InitiativeDetailsPage from "./pages/ProjectManagement/InitiativeDetails";
import DocumentsPage from "./pages/DocumentsPage/DocumentsPage";
import NewsPage from "./pages/NewsPage/NewsPage";
import NewsSinglePage from "./pages/NewsPage/NewsSinglePage";
import MonitoringPage from "./pages/Monitoring/MonitoringPage";
import SLAPage from "./pages/Monitoring/subpages/SLA/SLAPage";
// import CustomerSatisfactionPage from "./pages/Monitoring/subpages/CustomerSatisfaction/CustomerSatisfactionPage";
import DigitalizationIndexesPage from "./pages/Monitoring/subpages/DigitalizationIndexes/DigitalizationIndexesPage";

import ObservatoryPage from "./pages/Observatory/ObservatoryPage";


const LoadingSpinner = () => <h3>Caricamento...</h3>;

const RouteConfig = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {(context) => {
              return (
                <DefaultLayout>
                  <Suspense fallback={<LoadingSpinner loading={true} />}>
                    <Component {...props} />
                  </Suspense>
                </DefaultLayout>
              );
            }}
          </>
        );
      }}
    />
  );
};

const AppRoute = RouteConfig;

class AppRouter extends React.Component {
  render() {
    return (
      // Set the directory path if you are deploying in sub-folder
      <>
        <Router history={history}>
          <Switch>
            <Route exact path="/home">
              <DefaultLayout>
                <HomePage />
              </DefaultLayout>
            </Route>
            <Route exact path="/info">
              <DefaultLayout>
                <InfoPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/monitoring">
              <DefaultLayout>
                <MonitoringPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/monitoring/sla">
              <DefaultLayout>
                <SLAPage />
              </DefaultLayout>
            </Route>
            {/* <Route exact path="/monitoring/customer-satisfaction">
              <DefaultLayout>
                <CustomerSatisfactionPage />
              </DefaultLayout>
            </Route> */}
            <Route exact path="/monitoring/digitalization-indexes">
              <DefaultLayout>
                <DigitalizationIndexesPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/observatory">
              <DefaultLayout>
                <ObservatoryPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/login">
              <DefaultLayout>
                <LoginPage />
              </DefaultLayout>
            </Route>
            {/* <Route exact path="/authentication">
              <DefaultLayout>
                <AuthenticationPage />
              </DefaultLayout>
            </Route> */}
            <Route exact path="/projects/detail">
              <DefaultLayout>
                <InitiativeDetailsPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/projects">
              <DefaultLayout>
                <Projects />
              </DefaultLayout>
            </Route>
            <Route exact path="/projects/:id">
              <DefaultLayout>
                <ExecutivesPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/projects/:id/:mode">
              <DefaultLayout>
                <ExecutivesDocumentsPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/come-aderire">
              <DefaultLayout>
                <HowToJoinPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/documents">
              <DefaultLayout>
                <DocumentsPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/service">
              <DefaultLayout>
                <ServicePage />
              </DefaultLayout>
            </Route>
            <Route exact path="/news">
              <DefaultLayout>
                <NewsPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/news/:idNews">
              <DefaultLayout>
                <NewsSinglePage />
              </DefaultLayout>
            </Route>
            <Route exact path="/faq">
              <DefaultLayout>
                <FAQPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/aq">
              <DefaultLayout>
                <AQPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/chi-siamo">
              <DefaultLayout>
                <UsPage />
              </DefaultLayout>
            </Route>
            <Route exact path="/admin">
              <DefaultLayout>
                <AdminSection />
              </DefaultLayout>
            </Route>
            <Route exact path="/">
              <DefaultLayout>
                <HomePage />
              </DefaultLayout>
            </Route>
          </Switch>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.core.loading,
  };
};

export default connect(mapStateToProps)(AppRouter);
