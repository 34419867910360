import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import { getContractDetails } from "../../redux/actions/documents";
// import ManagementPage from "./components/ManagementPage";
// import TablePagination from "./components/TablePagination";
import { AccordionConsip } from "../../components/AccordionConsip";

export default function ExecutivesPage() {
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const params = useParams();
  const id = params?.id;
  const mode = params?.mode;
  const dispatch = useDispatch();

  const contractDetailsSpecial = useSelector(
    (state) => state.documents.contractDetailsSpecial
  );

  // const calculateTime = (end, start) => {
  //   const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  //   const lastDate = end !== undefined ? new Date(end) : new Date();
  //   const firstDate = new Date(start);
  //   return Math.round(Math.abs((lastDate - firstDate) / oneDay));
  // };

  const [modeTitle, setModeTitle] = useState("");

  useEffect(() => {
    if (!user?.user) history.push("/login");
    else {
      let idArr = id.split("_");
      let idValue = idArr.length > 1 ? idArr[1] : -1;
      dispatch(getContractDetails(idValue, user?.user?.sub));
      switch (mode) {
        case "plan":
          setModeTitle("Piano dei Fabbisogni");
          break;
        case "project":
          setModeTitle("Piano Operativo");
          break;
        case "contract":
          setModeTitle("Gestione Progettuale");
          break;
        case "management":
          setModeTitle("Gestione Progettuale");
          break;
        default:
          return "";
      }
    }
  }, []);

  return (
    <div className="info-gradient pb-5" style={{ minHeight: "60vh" }}>
      <div>
        <div className="container">
          <BreadcrumbConsip
            pages={[
              { label: "Project Management", url: "/projects" },
              { label: id?.replaceAll("-", "/"), url: `/projects/${id}` },
              { label: modeTitle, url: `/projects/${id}` },
            ]}
          />
        </div>
      </div>
      <div className="container">
        <div className="text-left">
          <h3>Contratto Esecutivo n.{id?.replaceAll("-", "/")}</h3>
        </div>
      </div>
      <div className="container px-3">
        <div className="row ">
          <div className="col-12 col-sm-6 col-lg-3  text-center pb-3">
            <div
              tag="button"
              className="row m-0 p-2 pointer-executive"
              onClick={() => {
                history.push(`/projects/${id}/plan`);
                setModeTitle("Piano dei Fabbisogni");
              }}
              style={
                contractDetailsSpecial?.pianodelfabbisogni
                  ? {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
                  : {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
              }
            >
              <div className="col-12 align-self-center">
                <h4 className="m-0">Piano dei Fabbisogni</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3  text-center pb-3">
            <div
              tag="button"
              className="row m-0 p-2 pointer-executive"
              onClick={() => {
                history.push(`/projects/${id}/project`);
                setModeTitle("Piano Operativo");
              }}
              
              style={
                contractDetailsSpecial?.pianooperativo
                  ? {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
                  : {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
              }
            >
              <div className="col-12 align-self-center">
                <h4 className="m-0">Piano Operativo</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 text-center pb-3">
            <div
              tag="button"
              className="row m-0 p-2 pointer-executive"
              onClick={() => {
                history.push(`/projects/${id}/contract`);
                setModeTitle("Contratto Esecutivo");
              }}
              style={
                contractDetailsSpecial?.contrattoesecutivo
                  ? {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
                  : {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
              }
            >
              <div className="col-12 align-self-center">
                <h4 className="m-0">Contratto Esecutivo</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3  text-centert pb-3">
            <div
              tag="button"
              className="row m-0 p-2 pointer-executive"
              onClick={() => {
                history.push(`/projects/${id}/management`);
                setModeTitle("Gestione Progettuale");
              }}
              style={{
                minHeight: "100%",
                border: "1px solid white",
                borderRadius: "5px",
                color: "white",
                background:
                  "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
              }}
            >
              <div className="col-12 align-self-center">
                <h4 className="m-0">Gestione Progettuale</h4>
              </div>
            </div>
          </div>
        </div>
        {contractDetailsSpecial !== undefined && (
          <div className="row">
            <>
              {Object.keys(
                mode === "plan"
                  ? contractDetailsSpecial?.pianodelfabbisogni
                  : mode === "project"
                  ? contractDetailsSpecial?.pianooperativo
                  : mode === "contract"
                  ? contractDetailsSpecial?.contrattoesecutivo
                  : mode === "management"
                  ? contractDetailsSpecial?.gestioneprogettuale
                  : []
              ).map((elem, index) => (
                <div key={elem} className="col-12 col-sm-6 text-left pb-3">
                  <div
                    className="px-2 py-2 h-100"
                    style={{
                      border: "1px solid #06c",
                      borderRadius: "5px",
                      backgroundColor: "white",
                    }}
                  >
                    <div class="row">
                      <div class="col-12 col-sm-6 col-lg-6  text-left pb-3">
                        <p style={{ margin: "0px" }}>
                          {mode === "plan"
                            ? contractDetailsSpecial?.pianodelfabbisogni[elem]
                                .section
                            : mode === "project"
                            ? contractDetailsSpecial?.pianooperativo[elem]
                                .section
                            : mode === "contract"
                            ? contractDetailsSpecial?.contrattoesecutivo[elem]
                                .section
                            : mode === "management"
                            ? contractDetailsSpecial?.gestioneprogettuale[elem]
                                .section
                            : []}
                        </p>
                      </div>
                      <div class="col-12 col-sm-3 col-lg-2  text-center pb-2"></div>
                      <div class="col-12 col-sm-3 col-lg-4  text-center pb-2">
                        <div
                          class="col-12"
                          style={{
                            borderRadius: "10px",
                            border: "1px solid",
                            // fontWeight: "bold",
                            fontSize: "18px",
                            color: "#06c",
                          }}
                        >
                          <p style={{ margin: "0px" }}>
                            {mode === "plan"
                              ? contractDetailsSpecial?.pianodelfabbisogni[elem]
                                  .section_status
                              : mode === "project"
                              ? contractDetailsSpecial?.pianooperativo[elem]
                                  .section_status
                              : mode === "contract"
                              ? contractDetailsSpecial?.contrattoesecutivo[elem]
                                  .section_status
                              : mode === "management"
                              ? contractDetailsSpecial?.gestioneprogettuale[
                                  elem
                                ].section_status
                              : []}
                          </p>
                        </div>
                      </div>
                    </div>
                    <AccordionConsip
                      data={
                        mode === "plan"
                          ? contractDetailsSpecial?.pianodelfabbisogni[elem]
                              .subSection
                          : mode === "project"
                          ? contractDetailsSpecial?.pianooperativo[elem]
                              .subSection
                          : mode === "contract"
                          ? contractDetailsSpecial?.contrattoesecutivo[elem]
                              .subSection
                          : mode === "management"
                          ? contractDetailsSpecial?.gestioneprogettuale[elem]
                              .subSection
                          : []
                      }
                    ></AccordionConsip>
                  </div>
                </div>
              ))}
            </>

            {/* <div className="col-12 py-4">
              <div className="container">
                <div className="text-left">
                  <h5>{modeTitle}</h5>
                </div>
              </div>
              <div className="container px-3 pt-3">
                <div className="text-left">
                  {modeTitle === "Piano Operativo"
                    ? contractDetailsSpecial?.pianooperativo?.length > 0
                      ? contractDetailsSpecial?.pianodelfabbisogni?.length > 0
                        ? "Tempo Trascorso per erogazione Piano Operativo: " +
                          calculateTime(
                            contractDetailsSpecial?.pianooperativo[
                              contractDetailsSpecial?.pianooperativo?.length - 1
                            ]?.startData,
                            contractDetailsSpecial?.pianodelfabbisogni[
                              contractDetailsSpecial?.pianodelfabbisogni
                                ?.length - 1
                            ]?.startData
                          )
                        : ""
                      : contractDetailsSpecial?.pianodelfabbisogni?.length > 0
                      ? "Tempo Trascorso dalla richiesta del Piano Operativo: " +
                        calculateTime(
                          undefined,
                          contractDetailsSpecial?.pianodelfabbisogni[
                            contractDetailsSpecial?.pianodelfabbisogni.length -
                              1
                          ]?.startData
                        )
                      : ""
                    : modeTitle === "Contratto Esecutivo"
                    ? contractDetailsSpecial?.contrattoesecutivo?.length > 0
                      ? contractDetailsSpecial?.pianooperativo?.length > 0
                        ? "Tempo Trascorso per erogazione Contratto Esecutivo: " +
                          calculateTime(
                            contractDetailsSpecial?.contrattoesecutivo[
                              contractDetailsSpecial?.contrattoesecutivo
                                ?.length - 1
                            ]?.startData,
                            contractDetailsSpecial?.pianooperativo[
                              contractDetailsSpecial?.pianooperativo.length - 1
                            ]?.startData
                          )
                        : ""
                      : contractDetailsSpecial?.pianooperativo?.length > 0
                      ? "Tempo Trascorso dalla richiesta del Contratto Esecutivo: " +
                        calculateTime(
                          undefined,
                          contractDetailsSpecial?.pianooperativo[
                            contractDetailsSpecial?.pianooperativo.length - 1
                          ]?.startData
                        )
                      : ""
                    : ""}
                </div>
              </div>
              {mode === "management" ? (
                <ManagementPage
                  data={contractDetailsSpecial?.gestioneprogettuale}
                ></ManagementPage>
              ) : (mode === "plan" &&
                  contractDetailsSpecial?.pianodelfabbisogni?.length > 0) ||
                (mode === "project" &&
                  contractDetailsSpecial?.pianooperativo?.length > 0) ||
                (mode === "contract" &&
                  contractDetailsSpecial?.contrattoesecutivo?.length > 0) ? (
                <AccordionConsip
                  data={
                    mode === "plan"
                      ? contractDetailsSpecial?.pianodelfabbisogni
                      : mode === "project"
                      ? contractDetailsSpecial?.pianooperativo
                      : mode === "contract"
                      ? contractDetailsSpecial?.contrattoesecutivo
                      : []
                  }
                ></AccordionConsip>
              ) : (
                <div className="p-3 text-left">
                  Non ci sono Documenti inseriti per questa sezione
                </div>
              )}
            </div> */}
          </div>
        )}
      </div>
      <>
        {(mode === "plan" &&
          Object.keys(contractDetailsSpecial?.pianodelfabbisogni).length > 0) ||
        (mode === "project" &&
          Object.keys(contractDetailsSpecial?.pianooperativo).length > 0) ||
        (mode === "contract" &&
          Object.keys(contractDetailsSpecial?.contrattoesecutivo).length >
            0) ? (
          <div className="container px-3">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-4 text-left pb-3">
                <div
                  class="col-12"
                  style={{
                    background: "white",
                    borderRadius: "10px",
                    color: "black",
                    display: "block",
                  }}
                >
                  <h5
                    style={{
                      display: "inline-block",
                      marginInlineStart: "15px",
                      marginTop: "10px",
                      marginBottom: "0px",
                    }}
                  >
                    LEGENDA
                  </h5>
                  <div class="row">
                    <div class="col-12 col-lg-2">
                      <div
                        class="container"
                        style={{
                          marginTop: "7px",
                          display: "block",
                          width: 20,
                          height: 20,
                          borderRadius: 20 / 2,
                          backgroundColor: "white",
                          border: "2px solid black",
                        }}
                      ></div>
                    </div>
                    <div class="col-12 col-lg-10">
                      <p>In lavorazione</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-2">
                      <div
                        class="container"
                        style={{
                          marginTop: "7px",
                          display: "block",
                          width: 20,
                          height: 20,
                          borderRadius: 20 / 2,
                          backgroundColor: "yellow",
                          border: "2px solid black",
                        }}
                      ></div>
                    </div>
                    <div class="col-12 col-lg-10">
                      <p>Bozza</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-2">
                      <div
                        class="container"
                        style={{
                          marginTop: "7px",
                          display: "block",
                          width: 20,
                          height: 20,
                          borderRadius: 20 / 2,
                          backgroundColor: "green",
                          border: "2px solid black",
                        }}
                      ></div>
                    </div>

                    <div class="col-12 col-lg-10">
                      <p>Versione rilasciata</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-2">
                      <div
                        class="container"
                        style={{
                          marginTop: "7px",
                          display: "block",
                          width: 20,
                          height: 20,
                          borderRadius: 20 / 2,
                          backgroundColor: "red",
                          border: "2px solid black",
                        }}
                      ></div>
                    </div>
                    <div class="col-12 col-lg-10">
                      <p>Versione precedente</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-2">
                      <div
                        class="container"
                        style={{
                          marginTop: "7px",
                          display: "block",
                          width: 20,
                          height: 20,
                          borderRadius: 20 / 2,
                          backgroundColor: "black",
                          border: "2px solid black",
                        }}
                      ></div>
                    </div>
                    <div class="col-12 col-lg-10">
                      <p>Documento di supporto</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    </div>
  );
}
