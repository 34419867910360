import React from "react";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import GuidePage from "../InfoPage/GuidePage";

export default function AQPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip pages={[{ label: "Accordo Quadro", url: "/aq" }]} />
      </div>
      <div className="pb-5">
        <div className="container">
          <div className="text-left pb-3">
            <h3>Accordo Quadro</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left pb-3">
              <p>
                A seguito dell’aggiudicazione del Lotto 2 “Gara a procedura
                aperta per la conclusione di un Accordo Quadro, ai sensi del
                D.Lgs. 50/2016 e s.m.i., suddivisa in 2 lotti e avente ad
                oggetto l'affidamento di servizi di Sicurezza da Remoto, di
                Compliance e Controllo per le Pubbliche Amministrazioni – ID
                2296 Lotto 2”, Consip S.p.A. ha stipulato un Accordo Quadro con
                il RTI Aggiudicatario, composto dalle aziende{" "}
                <b>
                  {" "}
                  Deloitte Risk Advisory S.r.l., EY Advisory S.p.A. e Teleco
                  S.r.l.
                </b>
              </p>
              <h6>SERVIZI</h6>
              <p>I servizi oggetto del Lotto in oggetto sono:</p>
              <ul>
                <li>Security Strategy (L2.S16)</li>
                <li>Vulnerability Assessment (L2.S17)</li>
                <li>Testing del codice – Statico (L2.S18)</li>
                <li>Testing del codice – Dinamico (L2.S19)</li>
                <li>Testing del codice – Mobile (L2.S20)</li>
                <li>
                  Supporto all’analisi e gestione degli incidenti (L2.S21)
                </li>
                <li>Penetration Testing (L2.S22)</li>
                <li>Compliance normativa (L2.S23)</li>
              </ul>
              <h6>CHI PUÒ ADERIRE</h6>
              <p>
                Gli Enti che possono aderire al presente Accordo Quadro sono le
                Pubbliche Amministrazioni Locali (PAL). Rientrano nelle PAL:
              </p>
              <ul>
                <li>Regioni</li>
                <li>Province autonome</li>
                <li>Province</li>
                <li>Città metropolitane</li>
                <li>Comuni</li>
                <li>Comunità montane</li>
                <li>Unioni di Comuni</li>
                <li>Istituti zooprofilattici sperimentali</li>
                <li>
                  Agenzie, Enti e Consorzi per il diritto allo studio
                  universitario
                </li>
                <li>Agenzie ed Enti per il turismo</li>
                <li>Agenzie ed Enti regionali per il lavoro</li>
                <li>
                  Agenzie ed Enti regionali e provinciali per la formazione,
                  ricerca ed ambiente
                </li>
                <li>Agenzie regionali per la rappresentanza negoziale</li>
                <li>Agenzie regionali per l’erogazione in agricoltura</li>
                <li>
                  Agenzie regionali sanitarie e aziende ed enti di supporto
                  all’S.S.N.
                </li>
                <li>
                  Enti di governo dei servizi idrici e/o dei rifiuti (ex AATO)
                </li>
                <li>Autorità di sistema portuale</li>
                <li>
                  Aziende ospedaliere, aziende ospedaliero-universitarie,
                  policlinici ed istituti di ricovero e cura a carattere
                  scientifico pubblici
                </li>
                <li>Aziende sanitarie locali</li>
                <li>
                  Camere di commercio, industria, artigianato e agricoltura e
                  unioni regionali
                </li>
                <li>Consorzi di bacino imbrifero montano</li>
                <li>Consorzi tra amministrazioni locali</li>
                <li>
                  Parchi nazionali, consorzi ed enti gestori di parchi ed aree
                  naturali protetti
                </li>
                <li>Consorzi interuniversitari di ricerca</li>
                <li>Agenzie ed Enti regionali di sviluppo agricolo</li>
                <li>Fondazioni lirico-sinfoniche</li>
                <li>Teatri nazionali e di rilevante interesse culturale</li>
                <li>
                  Università e Istituti di istruzione universitaria pubblici
                </li>
                <li>Altre Amministrazioni locali</li>
                <li>
                  Consorzi di funzione ed associazioni tra enti locali non
                  esercenti attività economiche
                </li>
                <li>Comunità isolane e di arcipelago</li>
                <li>Enti pubblici a carattere regionale e locale</li>
                <li>
                  Ogni altra Amministrazione e/o Ente di rilevanza regionale o
                  locale
                </li>
                <li>
                  Organismi di diritto pubblico e le Società, partecipati, anche
                  indirettamente, dai soggetti di cui a tutti i punti precedenti
                  qualificabili come stazioni appaltanti (in caso di società
                  partecipate da soggetti di tipologie diverse - es. partecipati
                  contestualmente da soggetti rientranti nella PAC e da soggetti
                  rientranti nella PAL – si intenderanno ricompresi nella PAL,
                  ai fini della presente iniziativa, gli Organismi di diritto
                  pubblico e le Società partecipati in misura maggioritaria,
                  anche indirettamente, dai soggetti di cui ai punti
                  precedenti).
                </li>
              </ul>
            </div>
            <div className="col-12 text-left">
              <GuidePage />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
