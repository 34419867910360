import { Badge } from "design-react-kit";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import PaginationConsip from "./../../ExecutivesPage/components/PaginationConsip";

export default function TablePaginationNews({ data }) {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const numberElemPage = 5;

  const dateValue = (date) => {
    if (date) {
      let newDate = new Date(date);
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return newDate.toLocaleDateString("it-IT", options);
    } else return "";
  };

  const openNews = (idNews) => {
    history.push(`/news/${idNews}`);
  };

  useEffect(() => {
    setPage(1);
  }, [data]);
  return (
    <div className="container px-3">
      <div>
        <div
          className="row text-left px-2"
          style={{
            backgroundColor: "#e6e9f2",
          }}
        >
          <div className="col-12">
            {data !== undefined &&
              data.map((elem, index) => {
                if (
                  index + 1 <= page * numberElemPage &&
                  index + 1 > (page - 1) * numberElemPage
                )
                  return (
                    <div
                      role="button"
                      key={index}
                      className="col-12 my-3 pointer-executive"
                      style={{
                        backgroundColor: "white",
                        minHeight: "30px",
                      }}
                      onClick={() => openNews(elem?.id_news)}
                    >
                      <div className="row">
                        <div className="col-12 text-left">
                          <h5>
                            Titolo: <b>{elem?.title}</b>
                          </h5>
                        </div>
                        <div className="col-12 text-left pb-2">
                          <Badge color="primary" pill>
                            {elem?.category}
                          </Badge>
                        </div>
                        <div className="col-12 col-sm-4 col-md-3 text-center align-self-center">
                          <img
                            src={elem?.url ? elem?.url : ""}
                            style={{
                              maxWidth: "150px",
                              maxHeight: "150px",
                              borderRadius: "10px",
                            }}
                            alt="news"
                          />
                        </div>
                        <div className="col-12 col-sm-8 col-md-9 text-left">
                          <div>
                            {elem?.body?.length > 200
                              ? elem?.body.substr(0, 199) + "..."
                              : elem?.body}
                          </div>
                        </div>

                        <div className="col-12 text-right">
                          {dateValue(elem?.date)}
                        </div>
                      </div>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>

      {data?.length > 5 && (
        <div className="row justify-content-center py-2">
          <div className="">
            <PaginationConsip
              totalPage={Math.ceil(data?.length / numberElemPage)}
              currentPage={page}
              setPage={setPage}
            ></PaginationConsip>
          </div>
        </div>
      )}
    </div>
  );
}
