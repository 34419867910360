import {
  Card,
  CardCategory,
  CardFooterCTA,
  CardReadMore,
  CardSignature,
  Icon,
} from "design-react-kit";
import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";
import { CardBody, CardFooter, CardSubtitle, div, CardTitle } from "reactstrap";
import { relativeTimeRounding } from "moment";


export const IndexCard = ({ title, data1, caption1, data2, caption2, data3, caption3, cardClass, updateDate }) => {
  return (
    <Card className={cardClass} noWrapper={false} tag="div" style={{ borderRadius: 8, borderCollapse: "separate" }}>
      <CardBody tag="div" style={{ minHeight: "100%", borderRadius: 6, borderCollapse: "separate" }}>
        <br></br>
        <CardTitle className="big-heading" style={{ fontSize: "21px", fontWeight: "bold" }}>
          {title}
        </CardTitle>
        <br></br>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-12 col-md-4" style={{ display: "flex" }}>
            <div className="container"
              /*style={{
                display: "flex" , justifyContent: "center", alignItems: "center"
                }}*/
              style={{ fontSize: "36px", color: "#06c", fontWeight: "bold" }}>
              {/* <ProgressBar
                label={
                  ""
                }
                style={{
                  borderRadius: "15px",
                  height: "18px",
                  border: "2px solid white",
                }}
                min={0}
                max={100
                }
                now={
                  75
                }
              /> */}
              {data1}
            </div>
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "17px", }}>
              {caption1}

            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "36px", color: "#1fa3e5", fontWeight: "bold" }}>
            {data2}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "17px", }}>
              {caption2}
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "36px", color: "#17cfc2", fontWeight: "bold" }}>
            {data3}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "17px" }}>
              {caption3}
            </div>
          </div>
        </div>

        {/* <br></br>
        <div tag="p" className="text-right" style={{fontSize: "14px"}}>
          Dati aggiorati al {updateDate}
        </div> */}

      </CardBody>
    </Card >
  );
};

export const IndexCard2 = ({ title, data1, caption1, data2, caption2, data3, caption3, cardClass, updateDate }) => {
  return (
    <Card className={cardClass} noWrapper={false} tag="div" style={{ borderRadius: 8, borderCollapse: "separate" }}>
      <CardBody tag="div" style={{ minHeight: "100%", borderRadius: 6, borderCollapse: "separate" }}>
        <br></br>
        <CardTitle className="big-heading" style={{ fontSize: "23px", fontWeight: "bold" }}>
          {title}
        </CardTitle>
        <br></br>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-12 col-md-4" style={{ display: "flex" }}>
            <div className="container" style={{ fontSize: "38px", color: "#06c", fontWeight: "bold" }}>
              {data1}
            </div>
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px", }}>
              {caption1}
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "38px", color: "#1fa3e5", fontWeight: "bold" }}>
            {data2}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px", }}>
              {caption2}
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "38px", color: "#17cfc2", fontWeight: "bold" }}>
            {data3}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px" }}>
              {caption3}
            </div>
          </div>
        </div>
        <br></br>
        <div tag="p" className="text-right" style={{ fontSize: "14px" }}>
          Dati aggiorati al {updateDate}
        </div>
      </CardBody>
    </Card >
  );
};
export const IndexCardBottom = ({ title, data1, caption1, data2, caption2, data3, caption3, data4, caption4, data5, caption5, data6, caption6, data7, caption7, data8, caption8, cardClass, updateDate }) => {
  return (
    <Card className={cardClass} noWrapper={false} tag="div" style={{ borderRadius: 8, borderCollapse: "separate" }}>
      <CardBody tag="div" style={{ minHeight: "100%", borderRadius: 6, borderCollapse: "separate" }}>
        <br></br>
        <CardTitle className="big-heading" style={{ fontSize: "23px", fontWeight: "bold" }}>
          {title}
        </CardTitle>
        <br></br>
        <br></br>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-12 col-md-4" style={{ display: "flex" }}>
            <div className="container" style={{ fontSize: "38px", color: "#06c", fontWeight: "bold" }}>
              {data1}
            </div>
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px" }}>
              {caption1}
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "36px", color: "#0073e6", fontWeight: "bold" }}>
            {data2}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px" }}>
              {caption2}
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "36px", color: "#0080ff", fontWeight: "bold" }}>
            {data3}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px" }}>
              {caption3}
            </div>
          </div>
          <br></br>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "36px", color: "#12add3", fontWeight: "bold" }}>
            {data4}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px" }}>
              {caption4}
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "36px", color: "#2cc6ed", fontWeight: "bold" }}>
            {data5}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px" }}>
              {caption5}
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "36px", color: "#5bd3f1", fontWeight: "bold" }}>
            {data6}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px" }}>
              {caption6}
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "36px", color: "#13ecdd", fontWeight: "bold" }}>
            {data7}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px" }}>
              {caption7}
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-12 col-md-4" style={{ fontSize: "36px", color: "#2beee1", fontWeight: "bold" }}>
            {data8}
          </div>
          {/* <div className="col-12 col-md-1">
          </div> */}
          <div className="col-12 col-md-7" style={{ alignSelf: "center" }}>
            <div tag="p" className="text-left" style={{ fontSize: "19px" }}>
              {caption8}
            </div>
          </div>
          <br></br>
        </div>
        <br></br>
        <div tag="p" className="text-right" style={{ fontSize: "14px" }}>
          Dati aggiorati al {updateDate}
        </div>
      </CardBody>
    </Card >
  );
};