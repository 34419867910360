import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useIntl } from "react-intl";
import CarouselConsip from "./CarouselConsip";
import ConsipNewsCard from "./NewsCard";
import {
  AvatarIcon,
  //Icon
} from "design-react-kit";
import { CookieBarComponent } from "../../components/CookieBar";
import {
  Link,
  //useHistory
} from "react-router-dom";
import {
  getNews,
  getHomePageAQNumbers,
  //getHomePageInitiatives,
} from "../../redux/actions/core";
//import ConsipInitiativeCard from "./InitiativeCard";

export default function HomePage() {
  const cookieBarOpen = true;
  //let history = useHistory();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getHomePageAQNumbers());
    dispatch(getNews(3));
    //dispatch(getHomePageInitiatives());
  }, []);

  let state = useSelector((state) => state.core);
  console.log("this is the state: ", state)
  return (
    <div>
      <CarouselConsip />
      <div
        className="text-center position-relative"
        style={{ backgroundColor: "rgb(245, 245, 245)", paddingBottom: 150 }}
      >
        <div className="py-5">
          <h3>I vantaggi dell'accordo quadro</h3>
        </div>
        <div>
          <div className="row text-center mx-5">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="card-wrapper">
                <div className="card card-img rounded bg-transparent align-items-center no-after">
                  <img
                    alt="Giovanna Ferrero"
                    width="100"
                    height="100"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/home-semplificazione.svg"
                    }
                  />
                  <div className="card-body text-center">
                    <h4 className="card-title">Semplificazione</h4>
                    <p className="card-text"></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="card-wrapper">
                <div className="card card-img bg-transparent align-items-center no-after">
                  <img
                    alt="alt"
                    width="100"
                    height="100"
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/home-velocita.svg"
                    }
                  />
                  <div className="card-body text-center">
                    <h4 className="card-title">Velocità</h4>
                    <p className="card-text"></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="card-wrapper">
                <div className="card card-img rounded bg-transparent align-items-center no-after">
                  <img
                    alt="personal"
                    width="100"
                    height="100"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/home-personalizzazione.svg"
                    }
                  />
                  <div className="card-body text-center">
                    <h4 className="card-title">Personalizzazione</h4>
                    <p className="card-text"></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="card-wrapper">
                <div className="card card-img rounded bg-transparent align-items-center no-after">
                  <img
                    alt="qualità"
                    width="100"
                    height="100"
                    src={
                      process.env.PUBLIC_URL + "/assets/icons/home-qualita.svg"
                    }
                  />
                  <div className="card-body text-center">
                    <h4 className="card-title">Qualità</h4>
                    <p className="card-text"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="it-btn-container">
          <Link className="btn btn-sm px-5 btn-primary" to="/aq">
            Scopri
          </Link>
        </div>
        <div className="custom-shape-divider-bottom-1627305719">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
              className="shape-fill"
            />
          </svg>
        </div>
      </div>

      <div className="container py-5 text-left">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="pb-2">
              <h3>I numeri dell'Accordo Quadro</h3>
              <p>
                I servizi sono forniti in linea con il Piano Triennale per
                l’Informatica della Pubblica Amministrazione in cui AgID fissa
                obiettivi e relative linee di azione con lo scopo di assolvere
                alla mission affidatale, che comprende le attività per la
                regolamentazione della cybersecurity nella PA. Tale compito è
                assolto con una focalizzazione particolare sugli aspetti di
                condivisione e collaborazione tra le varie Amministrazioni,
                cittadini e fornitori.
              </p>
              {/*
                <div className="it-btn-container">
                  <a className="btn btn-sm px-5 btn-primary" href="#a">
                    Scopri
                  </a>
                </div>
                */}
            </div>
          </div>
          <div className="col-12 col-md-8">
            <div
              className="detail-gradient-background text-white"
              style={{
                borderRadius: "15px",
              }}
            >
              <div className="container">
                <h1>
                  <img
                    alt=""
                    style={{
                      paddingLeft: "1vh",
                      marginRight: "1em",
                    }}
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/pm-initiatives-total.svg"
                    }
                  />
                  {state.homePageTotal}
                </h1>
                <p style={{ fontWeight: "bold" }}>
                  Numero totale di servizi inerenti all'Accordo Quadro
                </p>
              </div>
              <div
                className="row no-gutters"
                style={{ borderTop: "2px solid white" }}
              >
                <div className="col-6">
                  <div className="container">
                    <h2>
                      <img
                        alt=""
                        style={{ marginRight: "1em", paddingLeft: "1vh" }}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/icons/pm-initiatives-in-progress.svg"
                        }
                      />
                      {state.homePageInProgress}
                    </h2>
                    <p style={{ fontWeight: "bold" }}>Servizi avviati</p>
                  </div>
                </div>
                <div
                  className=" col-6"
                  style={{ borderLeft: "2px solid white" }}
                >
                  <div className="container">
                    <h2>
                      <img
                        alt=""
                        style={{ marginRight: "1em", paddingLeft: "1vh" }}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/icons/pm-inititives-finished.svg"
                        }
                      />
                      {state.homePageFinished}
                    </h2>
                    <p style={{ fontWeight: "bold" }}>Servizi conclusi</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/*<div
          className="position-relative text-center text-white section-gradient-background"
          style={{ paddingBottom: 80 }}
        >
          <div className="custom-shape-divider-top-1627317072 mb-5">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                className="shape-fill"
              />
            </svg>
          </div>
          <div className="container" style={{ paddingTop: 100 }}>
            <div>
              <h4>Iniziative</h4>
              <p>
                I servizi sono forniti in linea con la vision del Piano
                Triennale di AgID, focalizzandosi sugli aspetti di condivisione
                e collaborazione tra le varie Amministrazioni, cittadini e
                fornitori.
              </p>
            </div>
            {state.homePageInitiatives.map((item, id) => (
              <ConsipInitiativeCard {...item} />
            ))}
            <div className="text-right">
              <Link className="read-more text-white" to="#a">
                <span className="text">Visualizza tutte le iniziative</span>
                <Icon
                  color="white"
                  icon="it-arrow-right"
                  padding={false}
                  size="4"
                />
              </Link>
            </div>
            <div className="container my-4 p-4 rounded bg-dark-blue text-white text-left">
              <div className="row no-gutters">
                <div className="col-4"></div>
                <div className="col-8">
                  <h4>Accedi ai servizi</h4>
                  <br />
                  <p></p>
                  <div className="it-btn-container text-right">
                    <Link
                      className="btn btn-outline-secondary btn-sm px-5"
                      to="/login"
                    >
                      Accedi
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            </div>
          <div className="custom-shape-divider-bottom-1627305719">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                className="shape-fill"
              />
            </svg>
          </div>
        </div>*/}
        <div className="position-relative row text-center mx-5 mb-5">
          <div className="container-fluid" style={{ paddingTop: 50 }}>
            <div>
              <h4>News ed eventi</h4>
            </div>
            <div className="row mb-4">
              {state.homePageNews.map((item, id) => (
                <div key={id} className="col-4">
                  <ConsipNewsCard {...item} />
                </div>
              ))}
            </div>
            <div className="it-btn-container">
              <Link to="/news" className="btn btn-sm px-5 btn-primary">
                Vedi tutte le news
              </Link>
            </div>
          </div>
          {/* 
          <div className="col-3">
            <div className="card-wrapper">
              <div className="card card-img rounded no-after">
                <div className="img-responsive-wrapper">
                  <div className="img-responsive">
                    <figure className="img-wrapper">
                      <img
                        src="https://via.placeholder.com/310x94/0066cc/FFFFFF/?text=IMMAGINE%20DI%20ESEMPIO"
                        title="img title"
                        alt="imagealt"
                      />
                    </figure>
                  </div>
                </div>
                <div className="card-body text-center">
                  <h3 className="card-title">Area Informativa</h3>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card-wrapper card-space">
              <div className="card card-bg card-big">
                <div className="card-body">
                  <div className="top-icon">
                    <Icon color="" icon="it-card" padding={false} size="" />
                  </div>
                  <h5 className="card-title">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor…
                  </h5>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <a className="read-more" href="#a">
                    <span className="text">Leggi di più</span>
                    <Icon
                      color=""
                      icon="it-arrow-right"
                      padding={false}
                      size=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card-wrapper card-space">
              <div className="card card-bg card-big">
                <div className="card-body">
                  <div className="top-icon">
                    <Icon color="" icon="it-card" padding={false} size="" />
                  </div>
                  <h5 className="card-title">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor…
                  </h5>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <a className="read-more" href="#a">
                    <span className="text">Leggi di più</span>
                    <Icon
                      color=""
                      icon="it-arrow-right"
                      padding={false}
                      size=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card-wrapper card-space">
              <div className="card card-bg card-big">
                <div className="card-body">
                  <div className="top-icon">
                    <Icon color="" icon="it-card" padding={false} size="" />
                  </div>
                  <h5 className="card-title">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor…
                  </h5>
                  <p className="card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <a className="read-more" href="#a">
                    <span className="text">Leggi di più</span>
                    <Icon
                      color=""
                      icon="it-arrow-right"
                      padding={false}
                      size=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
         */}
        </div>
      </div>
      <CookieBarComponent open={cookieBarOpen} />
    </div>
  );
}
