//import { Icon } from "design-react-kit";
import React from "react";
//import { Badge } from "reactstrap";
import { BreadcrumbConsip } from "../../components/Breadcrumb";

export default function InitiativeDetailsPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip
          pages={[
            { label: "Project Management", url: "/projects" },
            { label: "Gestione Iniziativa", url: "/projects/detail" },
          ]}
        />
      </div>
      <div className="pb-5">
        <div className="container">
          <div className="text-left pb-5">
            <h4>Gestione dell'iniziativa</h4>
          </div>
        </div>
      </div>
    </>
  );
}
