import React, { Component } from "react";
import ConsipHeader from "../components/Header.js";
import ConsipFooter from "../components/Footer.js";

export default class DefaultLayout extends Component {
  render() {
    return (
      <div className="hello" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        <ConsipHeader />
        {this.props.children}
        <ConsipFooter />
      </div>
    );
  }
}
