const initialState = {
  languages: [
    {
      locale: "it",
      localeLabel: "Italiano",
      localeCode: "it_IT",
    },
    {
      locale: "en",
      localeLabel: "English",
      localeCode: "en_US",
    },
  ], // country specific deploy supported languages (english is only for reference if multilanguage)
  activeLocale: {
    locale: "it",
    localeLabel: "Italiano",
    localeCode: "it_IT",
  }, // Default and active language

  loading: false, //if true show a loading spinner

  refreshState: false,

  homePageTotal: 0,
  homePageInProgress: 0,
  homePageFinished: 0,
  homePageNews: [],
  homePageSingleNews: undefined,
  homePageInitiatives: [],
  section: [],
  superAdmin: undefined,
};

const startupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_HOMEPAGE_INITIATIVES":
      return {
        ...state,
        homePageInitiatives: action.payload,
      };
    case "SET_HOMEPAGE_NEWS":
      return {
        ...state,
        homePageNews: action.payload,
      };
    case "SET_SINGLE_NEWS":
      return {
        ...state,
        homePageSingleNews: action.payload,
      };
    case "SET_HOMEPAGE_AQNUMBERS":
      return {
        ...state,
        homePageTotal: action.payload.total,
        homePageInProgress: action.payload.inProgress,
        homePageFinished: action.payload.finished,
      };
    case "SET_ACTIVE_LOCALE":
      return {
        ...state,
        activeLocale: action.payload,
      };
    case "SET_ACTIVE_COUNTRY_WITH_STATES":
      return {
        ...state,
        activeCountry: action.payload,
      };
    case "SET_STORE_INFO":
      return {
        ...state,
        storeInfo: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_ADMIN":
      return {
        ...state,
        superAdmin: action.payload,
      };
    case "SET_REFRESH_STATE":
      return {
        ...state,
        refreshState: action.payload,
      };
    case "SET_MANAGER_CREDENTIALS":
      return {
        ...state,
        managerId: action.payload.managerId,
        managerPassword: action.payload.managerPassword,
      };
    case "SET_EMPLOYEE_CREDENTIALS":
      return {
        ...state,
        employeeId: action.payload.employeeId,
        employeePassword: action.payload.employeePassword,
      };
    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload,
      };
    case "SET_IDLE_TIMEOUT_ACTIVE":
      return {
        ...state,
        idleTimeoutActive: action.payload,
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
      };
    case "SET_SECTIONS":
      return {
        ...state,
        section: action.payload,
      };
    default:
      return state;
  }
};

export default startupReducer;
