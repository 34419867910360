import { toast } from "react-toastify";
import history from "../../history";
import "react-toastify/dist/ReactToastify.css";

import {
  apiCurrentSession,
  apiGetContracts,
  apiGetUserEntity,
  apiUserLogin,
  // apiUserConfirm,
  apiUserLogout,
} from "../../services/apiRequests";
import storageHandler from "../../utility/storage/storageHandler";
import { parseContractsDetails } from "../../utility/functions/functions";

export const userLogin = (credentials) => {
  //console.log("ACTION: userLogin");
  //devConsoleLog("Start Action userLogin");

  return (dispatch) => {
    //dispatch(setCustomerLoading(true));
    dispatch({ type: "SET_LOADING", payload: true });
    //console.log("Inside action");
    apiUserLogin(credentials.username, credentials.password)
      //.then((resp) => resp.json())
      .then((response) => {
        if (response.username) {
          storageHandler.set("username", response.username);
          dispatch(setToken(response.signInUserSession.accessToken.jwtToken));
          dispatch(
            setTokens({
              idToken: response.signInUserSession.idToken.jwtToken,
              accessToken: response.signInUserSession.accessToken.jwtToken,
            })
          );
          dispatch(setUser(response.signInUserSession.idToken.payload));
          dispatch({ type: "SET_LOADING", payload: false });

          history.push("/projects");
        } else {
          toast.error("Le Credenziali inserite non sono corrette!");
          //console.log("USER NON ESISTE");
          dispatch({ type: "SET_LOADING", payload: false });
        }
      });
  };
};

// export const userAuth = (code) => {
//   return (dispatch) => {
//     //dispatch(setCustomerLoading(true));
//     dispatch({ type: "SET_LOADING", payload: true });
//     // console.log(global.user);
//     apiUserConfirm(code["code"]).then((response) => {
//       if (response.username) {
//         // console.log(response);

//         storageHandler.set("username", response.username);
//         dispatch(setToken(response.signInUserSession.accessToken.jwtToken));
//         dispatch(
//           setTokens({
//             idToken: response.signInUserSession.idToken.jwtToken,
//             accessToken: response.signInUserSession.accessToken.jwtToken,
//           })
//         );
//         dispatch(setUser(response.signInUserSession.idToken.payload));
//         dispatch({ type: "SET_LOADING", payload: false });

//         history.push("/projects");
//       } else {
//         toast.error("Le Credenziali inserite non sono corrette!");
//         //console.log("USER NON ESISTE");
//         dispatch({ type: "SET_LOADING", payload: false });
//       }
//     });
//   };
// };

export const userLogout = () => {
  //console.log("ACTION: userLogout");
  return (dispatch) => {
    apiUserLogout().then(() => {
      history.push("/");
      dispatch({ type: "SET_USER_LOGOUT" });
    });
  };
};

export const getUserEntity = (idUser, selectedEntityId, selectedEntityName) => {
  //console.log("ACTION: userEntity");
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();
        return token;
      })
      .then((token) => {
        apiGetUserEntity(idUser, token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              //console.log("ACTION | apiGetUserEntity ERROR:", error);
              return { undefined };
            }
          })
          .then((responseEntity) => {
            if (responseEntity && responseEntity.length) {
              let entityID =
                selectedEntityId !== undefined
                  ? selectedEntityId
                  : responseEntity[0].id_ente;
              let entityName =
                selectedEntityName !== undefined
                  ? selectedEntityName
                  : responseEntity[0].ente_name;
              apiGetContracts(idUser, entityID, token)
                .then((responseContract) => {
                  try {
                    return responseContract.json();
                  } catch (error) {
                    //console.log("ACTION | apiGetContracts ERROR:", error);
                    return { undefined };
                  }
                })
                .then((responseContractValue) => {
                  dispatch({
                    type: "SET_USER_ENTITY",
                    payload: responseEntity,
                    ente: parseInt(entityID),
                    name: entityName,
                  });

                  let newData = undefined;
                  if (responseContractValue?.length) {
                    newData = parseContractsDetails(responseContractValue);
                  }
                  dispatch({ type: "SET_CONTRACTS", payload: newData });
                  dispatch({ type: "SET_LOADING", payload: false });
                });
            } else {
              dispatch({
                type: "SET_USER_ENTITY",
                payload: [],
                ente: undefined,
                name: "",
              });
              dispatch({ type: "SET_LOADING", payload: false });
            }
          });
      });
  };
};

// setter action

export const setUser = (data) => {
  return { type: "SET_USER_DATA", payload: data };
};

export const setToken = (data) => {
  //console.log("SET_USER_TOKEN");
  return { type: "SET_USER_TOKEN", payload: data };
};
export const setTokens = (data) => {
  return { type: "SET_USER_TOKENS", payload: data };
};
export const setUserLogout = () => {
  return { type: "SET_USER_LOGOUT" };
};
export const setCustomerLoading = (loading) => {
  return { type: "SET_CUSTOMER_LOADING", payload: loading };
};
