import Sla from "../../pages/Monitoring/subpages/SLA/SLAPage";
import { apiCurrentSession, apiDigIndexes, apiGetContracts, apiGetFilters, apiGetTipologiaBudget, apiMonitoringDetails, apiSLA, apiGetEcosistemBudget } from "../../services/apiRequests";
import { parseContractsDetails, parseFilters, parseMonitoringDetails, parseSLA, parseDigIndexes, parseTipologiaBudget, parseEcosistemBudget } from "../../utility/functions/functions";

export const getContracts = (userId, entityId) => {
  //console.log("ACTION | getContracts");
  //console.log("ACTION | getContracts().userId:", userId);
  //console.log("ACTION | getContracts().entityId:", entityId);

  return (dispatch) => {
    //console.log("ACTION | getContracts -> inside action");
    dispatch({ type: "SET_LOADING", payload: true });

    apiCurrentSession()
      .then((response) => {
        //console.log("CHHECK SESSION | session:", response);
        let token = response.getAccessToken().getJwtToken();
        return token;
      })
      .then((token) => {
        apiGetContracts(userId, entityId, token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return [];
            }
          })
          .then((response) => {
            let newData = undefined;
            if (response?.length) {
              //console.log(response?.length);
              newData = parseContractsDetails(response);
            }

            dispatch({ type: "SET_CONTRACTS", payload: newData });
            dispatch({ type: "SET_LOADING", payload: false });
          });
      });
  };
};

export const getFilters = () => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });

    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();;
        return token;
      })
      .then((token) => {
        apiGetFilters(token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return [];
            }
          })
          .then((response) => {
            let newData = undefined;
      
            newData = parseFilters(response);

            //console.log("ACTION | getFilters.response:", response);
            dispatch({ type: "SET_FILTERS", payload: newData });
            dispatch({ type: "SET_LOADING", payload: false });

          });
      });
  };
};

export const getMonitoringDetails = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });

    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();;
        return token;
      })
      .then((token) => {
        apiMonitoringDetails(data, token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return [];
            }
          })
          .then((response) => {
            let details = undefined;

            details = parseMonitoringDetails(response);

            //console.log("ACTION | getFilters.response:", response);
            dispatch({ type: "SET_MONITORING_DETAILS", payload: details });
            dispatch({ type: "SET_LOADING", payload: false });

          });
      });
  };
};

export const getSLA = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });

    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();;
        return token;
      })
      .then((token) => {
        apiSLA(data, token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return [];
            }
          })
          .then((response) => {
            let slaData = undefined;

            //if (response?.length) {
            //console.log(response?.length);
            slaData = parseSLA(response);
            console.log("this is the new data", slaData)
            //}


            //console.log("ACTION | getFilters.response:", response);
            dispatch({ type: "SET_SLA", payload: slaData });
            dispatch({ type: "SET_LOADING", payload: false });

          });
      });
  };
};

export const getDigIndexes = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });

    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();;
        return token;
      })
      .then((token) => {
        apiDigIndexes(data, token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return [];
            }
          })
          .then((response) => {
            let indexes = undefined;

            //if (response?.length) {
            //console.log(response?.length);
            indexes = parseDigIndexes(response);
            console.log("this is the new data", indexes)
            //}


            //console.log("ACTION | getFilters.response:", response);
            dispatch({ type: "SET_INDEXES", payload: indexes });
            dispatch({ type: "SET_LOADING", payload: false });

          });
      });
  };
};

export const getTipologiaBudget = (userId) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });

    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();;
        return token;
      })
      .then((token) => {
        apiGetTipologiaBudget(userId, token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return [];
            }
          })
          .then((response) => {
            let tipo = undefined;

            //if (response?.length) {
            //console.log(response?.length);
            tipo = parseTipologiaBudget(response);
            //console.log("this is the new data", topo)
            //}


            //console.log("ACTION | getFilters.response:", response);
            dispatch({ type: "SET_TIPOLOGIA", payload: tipo });
            dispatch({ type: "SET_LOADING", payload: false });

          });
      });
  };
};

export const getEcosistemBudget = (userId) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });

    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();;
        return token;
      })
      .then((token) => {
        apiGetEcosistemBudget(userId, token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return [];
            }
          })
          .then((response) => {
            let eco = undefined;

            //if (response?.length) {
            //console.log(response?.length);
            eco = parseEcosistemBudget(response);
            //console.log("this is the new data", topo)
            //}


            //console.log("ACTION | getFilters.response:", response);
            dispatch({ type: "SET_ECOSISTEM", payload: eco });
            dispatch({ type: "SET_LOADING", payload: false });

          });
      });
  };
};



