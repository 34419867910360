import React from "react";
import { BreadcrumbConsip } from "../../components/Breadcrumb";

export default function ServicesPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip pages={[{ label: "Chi Siamo", url: "/chi-siamo" }]} />
      </div>
      <div className="pb-5">
        <div className="container">
          <div className="text-left pb-3">
            <h3>Chi Siamo</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left pb-3">
              <img
                className="img my-2"
                src={"./assets/icons/deloitteLogo.png"}
                height={30}
                alt="deloitte"
              />
              <p className="text-justify">
                Deloitte Risk Advisory S.r.l. (di seguito DRA) fa parte della
                realtà Deloitte Italia con più di 7.700 dipendenti e 340.000 nel
                mondo con presenza in oltre 150 Paesi. La divisione Cyber Risk
                Services conta circa 350 professionisti in Italia. Il
                portafoglio Cyber Security di Deloitte copre tutti gli aspetti
                relativi alla riduzione dei rischi informatici dei propri
                clienti; ciò si compie attraverso servizi di Cyber Strategy,
                Detect & Respond, Application Security, Cyber Cloud,
                Infrastructure Security, Industrial & Product Security, Data &
                Privacy ed Identity. I professionisti di DRA sono distribuiti
                nelle sedi operative di Bari, Bologna, Firenze, Genova, Milano,
                Padova, Roma, Torino garantendo un importante presidio in tutto
                il territorio italiano al RTI nel suo complesso. Il business
                Cyber di Deloitte è costruito mediante un Global Network
                mondiale che conta più di 8.600 professionisti dedicati ai
                servizi di Cyber Risk coadiuvati da ulteriori 10.000+ risorse di
                altre aree focalizzate sui temi di security. Deloitte vanta
                inoltre più di 30 Cyber Intelligence Center che forniscono
                servizi di consulenza sui temi SOC, realizzano soluzioni di
                sicurezza gestite completamente personalizzabili, tra cui il
                monitoraggio avanzato della sicurezza, l’analisi e la gestione
                delle minacce cyber e la risposta agli incidenti per le
                aziende-clienti. Tutti i servizi sono erogati garantendo
                metodologie ed approcci ottimizzati e comuni, profonda
                attenzione ai livelli di qualità dei deliverable, soluzioni e
                strumenti operativi in continua evoluzione e un insieme globale
                di punti di osservazione della trasformazione digitale della
                Pubblica Amministrazione sia italiana che internazionale.
                Deloitte è stata nominata Leader tra i Cybersecurity Consulting
                Provider Europei da Forrester Wave, per il terzo trimestre 2021
                e, per il decimo anno consecutivo, si è classificata come Leader
                per Security Consulting Services Worldwide da Gartner (2020).
              </p>
              <img
                // className="img img-fluid"
                src={"./assets/icons/EYLogo.png"}
                height={50}
                alt="EYLogo"
              />
              <p className="text-justify">
                EY Advisory S.p.A. (di seguito EYA) fa parte della realtà EY
                Italia con più di 5.000 dipendenti, 312.000 nel modo con
                presenza in 150 paesi. La Cybersecurity & Digital Protection,
                all’interno di EY, conta più di 200 professionisti in Italia a
                supporto di clienti nazionali ed internazionali sulle tematiche
                di Cyber Strategy Risk Compliance & Resilience, Data Protection
                & Privacy, Identity & Access Management, Cyber Architecture
                Engineering & Emerging Technology e Next Generation Security
                Operations & Response. Tali professionisti appartengono alle
                sedi operative di Bari, Bologna, Milano, Roma, Torino e Treviso,
                garantendo presenza capillare sul territorio nazionale al RTI
                nel suo complesso. Il Network mondiale EY Cybersecurity è
                costituito da più di 14.000 risorse che si avvalgono di un
                insieme comune di metodologie, asset e approcci di settore, a
                garanzia di qualità dei deliverable, strumenti operativi
                consolidati e visione privilegiata sulle evoluzioni del settore
                Pubblico anche a livello internazionale. EY vanta inoltre più di
                60 Cybersecurity Center e 12 Advanced Security Center che
                forniscono servizi innovativi di sicurezza avvalendosi di
                soluzioni tecnologiche all’avanguardia. EY è stata nominata
                Leader tra i Cybersecurity Consulting Provider Europei da
                Forrester Wave (Luglio 2021) e Market Share Analysis Leader per
                i Security Consulting Services Worldwide da Gartner (Giugno
                2021).
              </p>
              <img
                // className="img img-fluid"
                src={"./assets/icons/TelecoLogo.png"}
                height={50}
                alt="TelecoLogo"
              />
              <p className="text-justify">
                TELECO S.r.l. (di seguito TEL) è una PMI Innovativa, registrata
                in CCIAA Roma al N. 220439/2019 e iscritta al MISE – Ministero
                dello Sviluppo Economico, che sviluppa attività di Ricerca &
                Sviluppo in ambito Sicurezza Informatica e Privacy,
                Vulnerability Assessment, Penetration Test, Application Security
                riferito anche ad ambienti ICS, OT, IoT ed utilizzando
                tecnologie innovative (BigData-Analytics). Dispone di una sede
                legale e operativa in Roma e un Polo Tecnologico in Cagliari con
                oltre 50 dipendenti tra quadri e figure operative con competenze
                trasversali, ai quali si uniscono società partner per il
                raggiungimento su base progetto di una disponibilità di oltre
                100 risorse, garantendo la presenza sull’intero territorio
                nazionale. Fondata nel 1999, opera anche come System Integrator
                con focalizzazione ad una clientela nel settore della Pubblica
                Amministrazione Centrale e Locale (Centro-Sud).
              </p>
            </div>
            <div className="col-12 text-left"></div>
          </div>
        </div>
      </div>
    </>
  );
}
