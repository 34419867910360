import React from "react";
import { Button } from "design-react-kit";
import { BreadcrumbConsip } from "../../components/Breadcrumb";

import { Formik, Field, Form } from "formik";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/actions/user";
import storageHandler from "../../utility/storage/storageHandler";
import { FormGroup } from "reactstrap";

export default function LoginPage() {
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  if (user?.user?.sub) {
    history.push("/projects");
  }
  const _username = storageHandler.get("username");
  //console.log("username:", _username);

  const handleLogin = (values) => {
    //console.log("LOGIN Values:", values);
    dispatch(userLogin(values));
  };

  return (
    <div>
      <div className="container text-left" style={{ paddingBottom: "30vh" }}>
        <BreadcrumbConsip pages={[{ label: "Login", url: "/login" }]} />
        <h3>Login</h3>
        <div className="row ">
          <div className="col-12 col-md-6 py-3">
            <Formik
              initialValues={{
                username: _username || "",
                password: "",
              }}
              onSubmit={handleLogin}
            >
              {({ values, errors }) => (
                <Form>
                  <FormGroup>
                    <Field
                      type="text"
                      name="username"
                      id="username"
                      placeholder="mariorossi@esempio.it"
                      className=""
                    />
                  </FormGroup>
                  <FormGroup>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      id="password"
                    />
                  </FormGroup>
                  {/*
                  <div className="text-right">
                    <a href="/login/recovery" className="go-back">
                      Reimposta Password
                    </a>
                  </div>
                  */}
                  <div>
                    <Button
                      color="secondary"
                      outline
                      type="reset"
                      className="mr-2"
                    >
                      Reset
                    </Button>
                    <Button color="primary" type="submit">
                      Accedi
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
