/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from "react";
import "./App.css";
import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import "typeface-titillium-web";
import "typeface-roboto-mono";
import "typeface-lora";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "./Router";

import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import ComponentSpinner from "./components/ComponentSpinner";
import { useDispatch, useSelector } from "react-redux";
Amplify.configure(awsconfig);

//import "bootstrap-italia/dist/css/bootstrap-italia.min.css";

const WebFont = require("webfontloader");
WebFont.load({
  custom: {
    families: [
      "Titillium Web:300,400,600,700:latin-ext",
      "Lora:400,700:latin-ext",
      "Roboto Mono:400,700:latin-ext",
    ],
  },
});

function App() {
  const loading = useSelector((state) => state.core.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: false });
    dispatch({ type: "SET_ADMIN", payload: undefined });
  });

  return (
    <div className={`App ${loading ? "overflow-hidden" : ""}`}>
      <>
        <AppRouter />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          rtl={false}
        />
        <ComponentSpinner loading={loading} />
      </>
    </div>
  );
}

export default App;
