import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import '../../style.css';

export const MonitoringTable = ({ data }) => {
  return (
    <table className="blueTable">
      <thead className="table_header">
        <tr>
          <th>Indicatore di qualità</th>
          <th>N. non conformità rilevate</th>
        {/*  <th>N. CE con non conformità rilevate</th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <img
              alt="icon"
              className="p-3"
              src={
                process.env.PUBLIC_URL +
                "/assets/icons/SLA-tableIcons/tableIcon1.png"
              }
            />
            Risorse ritenute inadeguate dall’Amministrazione <br></br>(Valore soglia 0)
          </td>
          <td>{data.risorse_inadeguate_sum}</td>
         {/* <td>{data.risorse_inadeguate_count}</td>*/}
        </tr>
        <tr>
          <td><img
            alt="icon"
            className="p-3"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/SLA-tableIcons/tableIcon2.png"
            }
          />Impegni assunti dal Fornitore non adempiuti <br></br>(Valore soglia 0)
          </td>
          <td>{data.impegni_non_adempiuti_sum}</td>
         {/* <td>{data.impegni_non_adempiuti_count}</td>*/}
        </tr>
        <tr>
          <td><img
            alt="icon"
            className="p-3"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/SLA-tableIcons/tableIcon3.png"
            }
          />Giorni di ritardo nella consegna di documentazione <br></br>(Valore soglia 1)

          </td>
          <td>{data.giorni_ritardo_sum}</td>
         {/* <td>{data.giorni_ritardo_count}</td>*/}
        </tr>
        <tr>

          <td>




            <img style={{ float: "left" }}
              alt="icon"
              className="p-3"
              src={
                process.env.PUBLIC_URL +
                "/assets/icons/SLA-tableIcons/tableIcon4.png"
              }
            />

            Risorse ritenute non rispondenti ai requisiti dall’Amministrazione <br></br>(Valore soglia 1)



          </td>

          <td>{data.risorse_non_rispondenti_requisiti_sum}</td>
         {/*  <td>{data.risorse_non_rispondenti_requisiti_count}</td>*/}
        </tr>
        <tr>
          <td><img
            alt="icon"
            className="p-3"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/SLA-tableIcons/tableIcon5.png"
            }
          />Risorse impiegate non certificate <br></br>(Valore soglia 0)
          </td>
          <td>{data.risorse_non_certificate_sum}</td>
        {/*  <td>{data.risorse_non_certificate_count}</td>*/}
        </tr>
        <tr>
          <td><img
            alt="icon"
            className="p-3"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/SLA-tableIcons/tableIcon6.png"
            }
          />Risorse sostituite su iniziativa del Fornitore <br></br>(Valore soglia 1)
          </td>
          <td>{data.risorse_sostituite_fornitore_sum}</td>
          {/* <td>{data.risorse_sostituite_fornitore_count}</td>*/}
        </tr>
        <tr>

          <td>

            <img style={{ float: "left" }}
              alt="icon"
              className="p-3"
              src={
                process.env.PUBLIC_URL +
                "/assets/icons/SLA-tableIcons/tableIcon7.png"
              }
            />Obbligazioni contrattuali non adempiute nei tempi e/o nei modi rappresentati nel CE <br></br>(Valore soglia 3)

          </td>

          <td>{data.obbligazioni_non_adempiute_sum}</td>
         {/* <td>{data.obbligazioni_non_adempiute_count}</td>*/}
        </tr>

        <tr>

          <td><img style={{ float: "left" }}
            alt="icon"
            className="p-3"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/SLA-tableIcons/tableIcon8.png"
            }
          />Rispetto scadenza pianificata per consegna di un deliverable <br></br>(Valore soglia 2)

          </td>
          <td>{data.rispetto_scadenza_sum}</td>
         {/* <td>{data.rispetto_scadenza_count}</td>*/}
        </tr>
        <tr>
          <td><img style={{ float: "left" }}
            alt="icon"
            className="p-3"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/SLA-tableIcons/tableIcon9.png"
            }
          />Rispetto degli standard dell’Amministrazione e linee guida degli Organismi di Controllo <br></br>(Valore soglia 1)

          </td>
          <td>{data.rispetto_standard_sum}</td>
         {/* <td>{data.rispetto_standard_count}</td>*/}
        </tr>
        <tr>
          <td><img style={{ float: "left" }}
            alt="icon"
            className="p-3"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/SLA-tableIcons/tableIcon9.png"
            }
          />Tempo di attivazione degli interventi a partire dalla richiesta dell’Amministrazione <br></br>(Valore soglia 0)
          </td>
          <td>{data.tempo_attivazione_sum}</td>
          {/* <td>{data.tempo_attivazione_count}</td>*/}
        </tr>
        {/* <tr>
          <td><img
            alt="icon"
            className="p-3"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/SLA-tableIcons/tableIcon9.png"
            }
          />Test con esiti negativi in fase di collaudo
          </td>
          <td>{data.test_negativi_collaudo_sum}</td>
          <td>{data.test_negativi_collaudo_count}</td>
        </tr>
        <tr>
          <td><img style={{ float: "left" }}
            alt="icon"
            className="p-3"
            src={
              process.env.PUBLIC_URL +
              "/assets/icons/SLA-tableIcons/tableIcon9.png"
            }
          />Giorni di sospensione del collaudo di un servizio per cause imputabili al Fornitore
          </td>
          <td>{data.giorni_sospensione_collaudo_sum}</td>
          <td>{data.giorni_sospensione_collaudo_count}</td>
        </tr> */}
      </tbody>
    </table>
  );
};

