import React from "react";
import { AccordionConsip } from "../../components/AccordionConsip";
import { BreadcrumbConsip } from "../../components/Breadcrumb";

const data = [
  {
    id: 0,
    title: "Security Strategy (L2.S16)",
    bodyStart: "",
    bodyBulletPoint: [
      "Supporto nella definizione e controllo delle scelte strategiche inerenti il governo della Sicurezza delle informazioni, degli indirizzi organizzativi, tecnologici e dell’approccio da adottare a fronte di nuovi paradigmi architetturali, scenari di attacco e situazioni di rischio consolidate;",
      "Supporto nella definizione delle scelte strategiche inerenti l’identificazione dei fabbisogni di beni e servizi in materia di IT Security.",
    ],
    bodyEnd: "",
    links: [],
  },
  {
    id: 1,
    title: "Vulnerability Assessment (L2.S17)",
    bodyStart: "",
    bodyBulletPoint: [
      " Il servizio consente di identificare lo stato di esposizione alle vulnerabilità mediante la raccolta di informazioni concernente i servizi erogati, le applicazioni, l’architettura e le componenti tecnologiche;",
    ],
    bodyEnd: "",
    links: [],
  },
  {
    id: 2,
    title: "Testing del codice – Statico (L2.S18)",
    bodyStart: "",
    bodyBulletPoint: [
      "Il servizio consente  l’identificazione delle vulnerabilità software all'interno del codice (sorgente o binario) delle applicazioni nella fase iniziale del ciclo di vita in modo da poterle eliminare prima della distribuzione.",
    ],
    bodyEnd: "",
    links: [],
  },
  {
    id: 3,
    title: "Testing del codice – Dinamico (L2.S19)",
    bodyStart: "",
    bodyBulletPoint: [
      "Il servizio consente l’identificazione delle vulnerabilità all'interno delle applicazioni Web in fase di esecuzione e l’analisi dell’esposizione al rischio di attacchi informatici ai sistemi informativi mediante l’utilizzo di tecniche di analisi dinamica.",
    ],
    bodyEnd: "",
    links: [],
  },
  {
    id: 4,
    title: "Testing del codice – Mobile (L2.S20)",
    bodyStart: "",
    bodyBulletPoint: [
      "Il servizio consente di eseguire test mirati alle applicazioni di tipo mobile consentendo la rilevazione delle vulnerabilità di sicurezza che possono essere sfruttate da un attaccante per compromettere i dati delle mobile app, la logica di business o il framework del dispositivo mobile identificando qualsiasi minaccia che mette a rischio l'applicazione e/o l'infrastruttura:",
    ],
    bodyEnd: "",
    links: [],
  },
  {
    id: 5,
    title: "Supporto all’analisi e gestione degli incidenti (L2.S21)",
    bodyStart: "",
    bodyBulletPoint: [
      "Il servizio consente alle Amministrazioni e agli organismi deputati alle attività di prevenzione, un supporto nelle sole fasi di analisi, progettazione e verifica (post-mortem) dei processi di gestione degli incidenti di sicurezza nonchè di supporto alla divulgazione delle informazioni.",
    ],
    bodyEnd: "",
    links: [],
  },
  {
    id: 6,
    title: "Penetration Testing (L2.S22)",
    bodyStart: "",
    bodyBulletPoint: [
      "Il servizio fornisce un processo operativo di analisi e valutazione dei punti deboli relativi ad una infrastruttura IT.",
    ],
    bodyEnd: "",
    links: [],
  },
  {
    id: 7,
    title: "Compliance normativa (L2.S23)",
    bodyStart: "",
    bodyBulletPoint: [
      "Il servizio consente un supporto nell’attuazione degli adempimenti del GDPR (General Data Protection Regulation - Regolamento UE 2016) applicato all’ambito del perimentro IT.",
    ],
    bodyEnd: "",
    links: [],
  },
];

export default function ServicesPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip pages={[{ label: "Servizi", url: "/service" }]} />
      </div>
      <div className="pb-5">
        <div className="container" style={{ paddingBottom: "20vh" }}>
          <div className="text-left pb-3">
            <h3>Servizi</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left pb-3">
              I servizi oggetto dell’Accordo Quadro sono:
            </div>
            <div className="col-12 text-left">
              <AccordionConsip data={data}></AccordionConsip>
            </div>
            <div className="col-12 text-left py-3">
              <p>
                Per maggiori dettagli si veda il capitolo 3 del Capitolato
                Tecnico Speciale – Lotto 2{" "}
                {/* <a
                  href={
                    "https://consip-public-files.s3.eu-west-1.amazonaws.com/Services+documents/03capitolato_tecnico.pdf"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Capitolato Tecnico Speciale – Lotti PMO
                </a> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
