import { combineReducers } from "redux";
import core from "./core";
import user from "./user";
import projectManagement from "./projectManagement";
import documents from "./documents";

const rootReducer = combineReducers({
  user: user,
  core,
  projectManagement,
  documents,
});

export default rootReducer;
