import {
  apiCurrentSession,
  apiGetContractDocuments,
  apiGetPrivateCommonDocuments,
  apiGetPublicDocuments,
  apiInsertDocuments,
} from "../../services/apiRequests";

export const getContractDetails = (contractId, userId) => {
  //console.log("ACTION | getContractDetails");

  return (dispatch) => {
    //console.log("ACTION | getContracts -> inside action");

    dispatch({ type: "SET_LOADING", payload: true });
    apiCurrentSession()
      .then((response) => {
        // console.log("CHECK SESSION | session:", response);
        let token = response.getAccessToken().getJwtToken();
        return token;
      })
      .then((token) => {
        apiGetContractDocuments(contractId, userId, token)
          .then((response) => {
            try {
              return response.json();
            } catch (error) {
              return [];
            }
          })
          .then((response) => {
            if (response) {
              let documents = {};
              let specialDocuments = {
                pianodelfabbisogni: {},
                pianooperativo: {},
                contrattoesecutivo: {},
                gestioneprogettuale: {},
              };
              response?.map((elem, index) => {
                if (
                  elem.sub_section_name &&
                  elem.section_name !== "Gestione Progettuale" &&
                  elem.section_name !== "Piano del Fabbisogni" &&
                  elem.section_name !== "Piano Operativo" &&
                  elem.section_name !== "Contratto Esecutivo"
                ) {
                  //Section exist?
                  if (documents[elem.section_id]) {
                    //SubSection exist?
                    let check = false;
                    documents[elem.section_id].subSection.map(
                      (found, indexFound) => {
                        if (found.id === elem.id_sub_section) {
                          found.links = [
                            ...found.links,
                            {
                              nameLink: elem.document_name,
                              link: elem.url,
                              status_color:
                                elem.status_color_name.toLowerCase(),
                            },
                          ];
                          check = true;
                        }
                      }
                    );
                    //add new SubSection and its name
                    if (!check) {
                      documents[elem.section_id].subSection = [
                        ...documents[elem.section_id].subSection,
                        {
                          id: elem.id_sub_section,
                          title: elem.sub_section_name,
                          reference: elem.reference,
                          links: [
                            {
                              nameLink: elem.document_name,
                              link: elem.url,
                              status_color:
                                elem.status_color_name.toLowerCase(),
                            },
                          ],
                        },
                      ];
                    }
                  }
                  //add new Section, Subsection and their name
                  else {
                    documents[elem.section_id] = {
                      subSection: [
                        {
                          id: elem.id_sub_section,
                          title: elem.sub_section_name,
                          reference: elem.reference,
                          links: [
                            {
                              nameLink: elem.document_name,
                              link: elem.url,
                              status_color:
                                elem.status_color_name.toLowerCase(),
                            },
                          ],
                        },
                      ],
                      section: elem.section_name,
                      section_status: elem.document_section_status_name,
                    };
                  }
                } else {
                  let sectionName = elem.section_name.replaceAll(" ", "");

                  switch (sectionName.toLowerCase()) {
                    case "pianodelfabbisogni":
                      if (
                        specialDocuments.pianodelfabbisogni[elem.section_id]
                      ) {
                        //SubSection exist?
                        let check = false;
                        specialDocuments.pianodelfabbisogni[
                          elem.section_id
                        ].subSection.map((found, indexFound) => {
                          if (found.id === elem.id_sub_section) {
                            found.links = [
                              ...found.links,
                              {
                                nameLink: elem.document_name,
                                link: elem.url,
                                status_color:
                                  elem.status_color_name.toLowerCase(),
                              },
                            ];
                            check = true;
                          }
                        });
                        //add new SubSection and its name
                        if (!check) {
                          specialDocuments.pianodelfabbisogni[
                            elem.section_id
                          ].subSection = [
                            ...specialDocuments.pianodelfabbisogni[
                              elem.section_id
                            ].subSection,
                            {
                              id: elem.id_sub_section,
                              title: elem.sub_section_name,
                              reference: elem.reference,
                              links: [
                                {
                                  nameLink: elem.document_name,
                                  link: elem.url,
                                  status_color:
                                    elem.status_color_name.toLowerCase(),
                                },
                              ],
                            },
                          ];
                        }
                      }
                      //add new Section, Subsection and their name
                      else {
                        specialDocuments.pianodelfabbisogni[elem.section_id] = {
                          subSection: [
                            {
                              id: elem.id_sub_section,
                              title: elem.sub_section_name,
                              reference: elem.reference,
                              links: [
                                {
                                  nameLink: elem.document_name,
                                  link: elem.url,
                                  status_color:
                                    elem.status_color_name.toLowerCase(),
                                },
                              ],
                            },
                          ],
                          section: elem.section_name,
                          section_status: elem.document_section_status_name,
                        };
                      }

                      // if (specialDocuments.pianodelfabbisogni) {
                      //   specialDocuments.pianodelfabbisogni = [
                      //     ...specialDocuments.pianodelfabbisogni,
                      //     {
                      //       title: elem.document_name,
                      //       url: elem.url,
                      //       startData: elem.date_oad,
                      //     },
                      //   ];
                      // } else {
                      //   specialDocuments.pianodelfabbisogni = [
                      //     {
                      //       title: elem.document_name,
                      //       url: elem.url,
                      //       startData: elem.date_oad,
                      //     },
                      //   ];
                      // }
                      break;
                    case "pianooperativo":
                      if (specialDocuments.pianooperativo[elem.section_id]) {
                        //SubSection exist?
                        let check = false;
                        specialDocuments.pianooperativo[
                          elem.section_id
                        ].subSection.map((found, indexFound) => {
                          if (found.id === elem.id_sub_section) {
                            found.links = [
                              ...found.links,
                              {
                                nameLink: elem.document_name,
                                link: elem.url,
                                status_color:
                                  elem.status_color_name.toLowerCase(),
                              },
                            ];
                            check = true;
                          }
                        });
                        //add new SubSection and its name
                        if (!check) {
                          specialDocuments.pianooperativo[
                            elem.section_id
                          ].subSection = [
                            ...specialDocuments.pianooperativo[elem.section_id]
                              .subSection,
                            {
                              id: elem.id_sub_section,
                              title: elem.sub_section_name,
                              reference: elem.reference,
                              links: [
                                {
                                  nameLink: elem.document_name,
                                  link: elem.url,
                                  status_color:
                                    elem.status_color_name.toLowerCase(),
                                },
                              ],
                            },
                          ];
                        }
                      }
                      //add new Section, Subsection and their name
                      else {
                        specialDocuments.pianooperativo[elem.section_id] = {
                          subSection: [
                            {
                              id: elem.id_sub_section,
                              title: elem.sub_section_name,
                              reference: elem.reference,
                              links: [
                                {
                                  nameLink: elem.document_name,
                                  link: elem.url,
                                  status_color:
                                    elem.status_color_name.toLowerCase(),
                                },
                              ],
                            },
                          ],
                          section: elem.section_name,
                          section_status: elem.document_section_status_name,
                        };
                      }

                      // if (specialDocuments.pianooperativo)
                      //   specialDocuments.pianooperativo = [
                      //     ...specialDocuments.pianooperativo,
                      //     {
                      //       title: elem.document_name,
                      //       url: elem.url,
                      //       startData: elem.date_oad,
                      //     },
                      //   ];
                      // else
                      //   specialDocuments.pianooperativo = [
                      //     {
                      //       title: elem.document_name,
                      //       url: elem.url,
                      //       startData: elem.date_oad,
                      //     },
                      //   ];
                      break;
                    case "contrattoesecutivo":
                      if (
                        specialDocuments.contrattoesecutivo[elem.section_id]
                      ) {
                        //SubSection exist?
                        let check = false;
                        specialDocuments.contrattoesecutivo[
                          elem.section_id
                        ].subSection.map((found, indexFound) => {
                          if (found.id === elem.id_sub_section) {
                            found.links = [
                              ...found.links,
                              {
                                nameLink: elem.document_name,
                                link: elem.url,
                                status_color:
                                  elem.status_color_name.toLowerCase(),
                              },
                            ];
                            check = true;
                          }
                        });
                        //add new SubSection and its name
                        if (!check) {
                          specialDocuments.contrattoesecutivo[
                            elem.section_id
                          ].subSection = [
                            ...specialDocuments.contrattoesecutivo[
                              elem.section_id
                            ].subSection,
                            {
                              id: elem.id_sub_section,
                              title: elem.sub_section_name,
                              reference: elem.reference,
                              links: [
                                {
                                  nameLink: elem.document_name,
                                  link: elem.url,
                                  status_color:
                                    elem.status_color_name.toLowerCase(),
                                },
                              ],
                            },
                          ];
                        }
                      }
                      //add new Section, Subsection and their name
                      else {
                        specialDocuments.contrattoesecutivo[elem.section_id] = {
                          subSection: [
                            {
                              id: elem.id_sub_section,
                              title: elem.sub_section_name,
                              reference: elem.reference,
                              links: [
                                {
                                  nameLink: elem.document_name,
                                  link: elem.url,
                                  status_color:
                                    elem.status_color_name.toLowerCase(),
                                },
                              ],
                            },
                          ],
                          section: elem.section_name,
                          section_status: elem.document_section_status_name,
                        };
                      }

                      // if (specialDocuments.contrattoesecutivo)
                      //   specialDocuments.contrattoesecutivo = [
                      //     ...specialDocuments.contrattoesecutivo,
                      //     {
                      //       title: elem.document_name,
                      //       url: elem.url,
                      //       startData: elem.date_oad,
                      //     },
                      //   ];
                      // else
                      //   specialDocuments.pianodelfabbisogni = [
                      //     {
                      //       title: elem.document_name,
                      //       url: elem.url,
                      //       startData: elem.date_oad,
                      //     },
                      //   ];
                      break;
                    case "gestioneprogettuale":
                      if (
                        specialDocuments.gestioneprogettuale[elem.section_id]
                      ) {
                        //SubSection exist?
                        let check = false;
                        specialDocuments.gestioneprogettuale[
                          elem.section_id
                        ].subSection.map((found, indexFound) => {
                          if (found.id === elem.id_sub_section) {
                            found.links = [
                              ...found.links,
                              {
                                nameLink: elem.document_name,
                                link: elem.url,
                                status_color:
                                  elem.status_color_name.toLowerCase(),
                              },
                            ];
                            check = true;
                          }
                        });
                        //add new SubSection and its name
                        if (!check) {
                          specialDocuments.gestioneprogettuale[
                            elem.section_id
                          ].subSection = [
                            ...specialDocuments.gestioneprogettuale[
                              elem.section_id
                            ].subSection,
                            {
                              id: elem.id_sub_section,
                              title: elem.sub_section_name,
                              reference: elem.reference,
                              links: [
                                {
                                  nameLink: elem.document_name,
                                  link: elem.url,
                                  status_color:
                                    elem.status_color_name.toLowerCase(),
                                },
                              ],
                            },
                          ];
                        }
                      }
                      //add new Section, Subsection and their name
                      else {
                        specialDocuments.gestioneprogettuale[elem.section_id] =
                          {
                            subSection: [
                              {
                                id: elem.id_sub_section,
                                title: elem.sub_section_name,
                                reference: elem.reference,
                                links: [
                                  {
                                    nameLink: elem.document_name,
                                    link: elem.url,
                                    status_color:
                                      elem.status_color_name.toLowerCase(),
                                  },
                                ],
                              },
                            ],
                            section: elem.section_name,
                            section_status: elem.document_section_status_name,
                          };
                      }

                      // let subSectionName = elem.sub_section_name.replaceAll(
                      //   " ",
                      //   ""
                      // );
                      // subSectionName = subSectionName.toLowerCase();
                      // if (
                      //   specialDocuments.gestioneprogettuale &&
                      //   specialDocuments.gestioneprogettuale[subSectionName]
                      // ) {
                      //   specialDocuments.gestioneprogettuale[subSectionName] = [
                      //     ...specialDocuments.gestioneprogettuale[
                      //       subSectionName
                      //     ],
                      //     { title: elem.document_name, url: elem.url },
                      //   ];
                      // } else {
                      //   if (!specialDocuments.gestioneprogettuale)
                      //     specialDocuments.gestioneprogettuale = {};

                      //   specialDocuments.gestioneprogettuale[subSectionName] = [
                      //     { title: elem.document_name, url: elem.url },
                      //   ];
                      // }
                      break;
                    default: {
                    }
                  }
                }
              });

              dispatch({
                type: "SET_CONTRACT_DETAILS",
                payload: documents,
                sp: specialDocuments,
              });
              dispatch({ type: "SET_LOADING", payload: false });
            } else {
              //console.log("OK");
              dispatch({
                type: "SET_CONTRACT_DETAILS",
                payload: undefined,
                sp: undefined,
              });
              dispatch({ type: "SET_LOADING", payload: false });
            }
          });
      });
  };
};

export const getPrivateCommonDocuments = (doc) => {
  //console.log("ACTION | getPrivateCommonDocuments", doc);
  return (dispatch) => {
    if (doc !== undefined && doc !== null && doc !== "") {
      dispatch({ type: "SET_LOADING", payload: true });
      apiCurrentSession()
        .then((response) => {
          let token = response.getAccessToken().getJwtToken();
          return token;
        })
        .then((token) => {
          apiGetPrivateCommonDocuments(doc, token)
            .then((response) => {
              try {
                return response.json();
              } catch (error) {
                //console.log("ACTION | getPrivateCommonDocuments ERROR:", error);
                return { undefined };
              }
            })
            .then((responseDoc) => {
              if (responseDoc) {
                dispatch({
                  type: "SET_PRIVATE_COMMON_DOCUMENTS",
                  payload: responseDoc,
                  doc: doc,
                });
                dispatch({ type: "SET_LOADING", payload: false });
              } else {
                dispatch({
                  type: "SET_PRIVATE_COMMON_DOCUMENTS",
                  doc: "",
                });
                dispatch({ type: "SET_LOADING", payload: false });
              }
            });
        });
    } else
      dispatch({
        type: "SET_PRIVATE_COMMON_DOCUMENTS",
        doc: "",
      });
  };
};

export const insertDocuments = (values) => {
  //console.log("ACTION | insertDocuments", values);
  return (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    apiCurrentSession()
      .then((response) => {
        let token = response.getAccessToken().getJwtToken();
        return token;
      })
      .then((token) => {
        apiInsertDocuments(values, token)
          .then((response) => {
            try {
              return response;
            } catch (error) {
              //console.log("ACTION | insertDocuments ERROR:", error);
              dispatch({ type: "SET_LOADING", payload: false });
              return { undefined };
            }
          })
          .then((responseDoc) => {
            if (responseDoc === "Success") {
              dispatch({
                type: "SET_INSERTED_DOCUMENT",
                payload: responseDoc,
              });
              dispatch({ type: "SET_LOADING", payload: false });
            } else {
              dispatch({
                type: "SET_INSERTED_DOCUMENT",
                payload: "Failure",
              });
              dispatch({ type: "SET_LOADING", payload: false });
            }
          });
      });
  };
};

export const getPublicDocuments = () => {
  //console.log("ACTION | getPublicDocuments");
  return (dispatch) => {
    apiGetPublicDocuments()
      .then((response) => {
        try {
          return response.json();
        } catch (error) {
          //console.log("ACTION | GetPublicDocuments ERROR:", error);
          return { undefined };
        }
      })
      .then((responseDoc) => {
        if (responseDoc) {
          dispatch({
            type: "SET_PUBLIC_DOCUMENTS",
            payload: responseDoc,
          });
        } else {
          dispatch({
            type: "SET_PUBLIC_DOCUMENTS",
            payload: [],
          });
        }
      });
  };
};
