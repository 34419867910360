import AES from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";

let prefix = "consip-";

/**
 * @description Handles storage read write
 * @class StorageHandler
 */
class storageHandler {
  /**
   * @description Set item value in store
   * @static
   * @param {string} item Item key
   * @param {*} value Item value
   * @memberof StorageHandler
   */
  static set(item, value) {
    sessionStorage.setItem(prefix + item, value);
  }

  /**
   * @description Get item value from store
   * @static
   * @param {*} item Item key
   * @returns {*} Value or null
   * @memberof StorageHandler
   */
  static get(item) {
    return sessionStorage.getItem(prefix + item);
  }

  /**
   * @description Encode object with secret
   * @static
   * @param {*} item
   * @param {*} value
   * @memberof storageHandler
   */
  static encode(item, value) {
    const content = AES.encrypt(
      JSON.stringify(value),
      process.env.REACT_APP_SECRET
    );
    sessionStorage.setItem(prefix + item, content);
  }

  /**
   * @description Decode object with secret
   * @static
   * @param {*} item
   * @return {*}
   * @memberof storageHandler
   */
  static decode(item) {
    const content = sessionStorage.getItem(prefix + item);
    try {
      const bytes = AES.decrypt(content, process.env.REACT_APP_SECRET);
      const decoded = bytes.toString(enc);
      return JSON.parse(decoded);
    } catch (error) {
      return content;
    }
  }

  /**
   * @description Remove item value from store
   * @static
   * @param {*} item Item key
   * @returns {*} Value or null
   * @memberof StorageHandler
   */
  static remove(item) {
    return sessionStorage.removeItem(prefix + item);
  }

  /**
   * @description Clear all the store (timeout or logout)
   * @static
   * @memberof storageHandler
   */
  static clearStorage() {
    sessionStorage.clear();
  }
}

export default storageHandler;
