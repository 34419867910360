import { BottomNav, Icon } from "design-react-kit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import { getNews } from "../../redux/actions/core";
import TablePaginationNews from "./components/TablePagination";

export default function NewsPage() {
  const [active, setActive] = useState("1");
  const dispatch = useDispatch();
  const news = useSelector((state) => state.core.homePageNews);

  useEffect(() => {
    dispatch(getNews());
  }, []);

  return (
    <div
      className="container "
      style={{ minHeight: "60vh", paddingBottom: "52.7vh" }}
    >
      <div className="">
        <BreadcrumbConsip pages={[{ label: "News", url: "/news" }]} />
      </div>
      <div className="row pb-2">
        <div className="col-12">
          <div className="text-left ">
            <h3>News</h3>

            {news?.length > 0 ? (
              <TablePaginationNews data={news}></TablePaginationNews>
            ) : (
              <p>Non ci sono articoli da mostrare.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
