import React, { useEffect, useState } from "react";
import { Executives } from "./Executives";
import PaginationConsip from "./../../ExecutivesPage/components/PaginationConsip";

export default function TablePaginationExecutive({ data }) {
  const [page, setPage] = useState(1);
  const numberElemPage = 5;

  useEffect(() => {
    setPage(1);
  }, [data]);
  return (
    <div className="container px-3">
      <div>
        <div
          className="row text-left py-2"
          style={{
            backgroundColor: "#e6e9f2",
          }}
        >
          <div className="col-12">
            {data !== undefined &&
              data.map((elem, index) => {
                if (
                  index + 1 <= page * numberElemPage &&
                  index + 1 > (page - 1) * numberElemPage
                )
                  return (
                    <div
                      key={index}
                      className="col-12 p-2 my-3"
                      style={{
                        backgroundColor: "white",
                        minHeight: "30px",
                      }}
                    >
                      <Executives
                        key={index}
                        id={"id_" + elem.id_contratto}
                        started={elem.startedInitiatives}
                        ended={elem.endedInitiatives}
                        totalBudget={elem.budget}
                        usedBudget={elem.usedBudget}
                      ></Executives>
                    </div>
                  );
              })}
          </div>
        </div>
      </div>

      {data?.length > 5 && (
        <div className="row justify-content-center py-2">
          <div className="">
            <PaginationConsip
              totalPage={Math.ceil(data?.length / numberElemPage)}
              currentPage={page}
              setPage={setPage}
            ></PaginationConsip>
          </div>
        </div>
      )}
    </div>
  );
}
