import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AccordionConsip } from "../../components/AccordionConsip";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import { getContractDetails } from "../../redux/actions/documents";

export default function ExecutivesPage() {
  const history = useHistory();
  const params = useParams();
  const id = params?.id;
  const dispatch = useDispatch();
  const contractDetails = useSelector(
    (state) => state.documents.contractDetails
  );
  const contractDetailsSpecial = useSelector(
    (state) => state.documents.contractDetailsSpecial
  );
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user?.user) history.push("/login");
    else {
      let idArr = id.split("_");
      let idValue = idArr.length > 1 ? idArr[1] : -1;
      dispatch(getContractDetails(idValue, user?.user?.sub));
    }
  }, []);

  return (
    <div className="info-gradient pb-5" style={{ minHeight: "60vh" }}>
      <div>
        <div className="container">
          <BreadcrumbConsip
            pages={[
              { label: "Project Management", url: "/projects" },
              { label: id?.replaceAll("-", "/"), url: `/projects/${id}` },
            ]}
          />
        </div>
      </div>
      <div className="container">
        <div className="text-left pb-5">
          <h3>Contratto Esecutivo n.{id?.replaceAll("-", "/")}</h3>
        </div>
      </div>
      <div className="container px-3">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-3  text-center pb-3">
            <div
              tag="button"
              className="row m-0 p-2 pointer-executive"
              onClick={() => {
                history.push(`/projects/${id}/plan`);
              }}
              style={
                contractDetailsSpecial?.pianodelfabbisogni
                  ? {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
                  : {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
              }
            >
              <div className="col-12 align-self-center">
                <h4 className="m-0">Piano dei Fabbisogni</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3  text-center pb-3">
            <div
              tag="button"
              className="row m-0 p-2 pointer-executive"
              onClick={() => {
                history.push(`/projects/${id}/project`);
              }}
              style={
                contractDetailsSpecial?.pianooperativo
                  ? {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
                  : {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
              }
            >
              {" "}
              <div className="col-12 align-self-center">
                <h4 className="m-0">Piano Operativo</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 text-center pb-3">
            <div
              tag="button"
              className="row m-0 p-2 pointer-executive"
              onClick={() => {
                history.push(`/projects/${id}/contract`);
              }}
              style={
                contractDetailsSpecial?.contrattoesecutivo
                  ? {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
                  : {
                      minHeight: "100%",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
                    }
              }
            >
              {" "}
              <div className="col-12 align-self-center">
                <h4 className="m-0">Contratto esecutivo</h4>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3  text-centert pb-3">
            <div
              tag="button"
              className="row m-0 p-2 pointer-executive"
              onClick={() => {
                history.push(`/projects/${id}/management`);
              }}
              style={{
                minHeight: "100%",
                border: "1px solid white",
                borderRadius: "5px",
                color: "white",
                background:
                  "linear-gradient(45deg, rgb(0, 102, 204), turquoise)",
              }}
            >
              {" "}
              <div className="col-12 align-self-center">
                <h4 className="m-0">Gestione progettuale</h4>
              </div>
            </div>
          </div>
        </div>
        {contractDetails !== undefined && (
          <div className="row">
            <>
              {Object.keys(contractDetails).map((elem, index) => (
                <div key={elem} className="col-12 col-sm-6 text-left pb-3">
                  <div
                    className="px-2 py-2 h-100"
                    style={{
                      border: "1px solid #06c",
                      borderRadius: "5px",
                      backgroundColor: "white",
                    }}
                  >
                    <div class="row">
                      <div class="col-12 col-sm-6 col-lg-6  text-left pb-3">
                        <p>{contractDetails[elem].section}</p>
                      </div>
                      <div class="col-12 col-sm-6 col-lg-2  text-center pb-3"></div>
                      <div class="col-12 col-sm-6 col-lg-4  text-center pb-3">
                        <div
                          class="col-12"
                          style={{
                            borderRadius: "10px",
                            border: "1px solid",
                            // fontWeight: "bold",
                            fontSize: "18px",
                            color: "#06c",
                          }}
                        >
                          <p style={{ margin: "0px" }}>
                            {contractDetails[elem].section_status}
                          </p>
                        </div>
                      </div>
                    </div>
                    <AccordionConsip
                      data={contractDetails[elem].subSection}
                      section={contractDetails[elem].section}
                    ></AccordionConsip>
                  </div>
                </div>
              ))}
            </>
          </div>
        )}
      </div>
      <div className="container px-3">
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-4 text-left pb-3">
            <div
              class="col-12"
              style={{
                background: "white",
                borderRadius: "10px",
                color: "black",
                display: "block",
              }}
            >
              <h5
                style={{
                  display: "inline-block",
                  marginInlineStart: "15px",
                  marginTop: "10px",
                  marginBottom: "0px",
                }}
              >
                LEGENDA
              </h5>
              <div class="row">
                <div class="col-12 col-lg-2">
                  <div
                    class="container"
                    style={{
                      marginTop: "7px",
                      display: "block",
                      width: 20,
                      height: 20,
                      borderRadius: 20 / 2,
                      backgroundColor: "white",
                      border: "2px solid black",
                    }}
                  ></div>
                </div>
                <div class="col-12 col-lg-10">
                  <p>In lavorazione</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-2">
                  <div
                    class="container"
                    style={{
                      marginTop: "7px",
                      display: "block",
                      width: 20,
                      height: 20,
                      borderRadius: 20 / 2,
                      backgroundColor: "yellow",
                      border: "2px solid black",
                    }}
                  ></div>
                </div>
                <div class="col-12 col-lg-10">
                  <p>Bozza</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-2">
                  <div
                    class="container"
                    style={{
                      marginTop: "7px",
                      display: "block",
                      width: 20,
                      height: 20,
                      borderRadius: 20 / 2,
                      backgroundColor: "green",
                      border: "2px solid black",
                    }}
                  ></div>
                </div>

                <div class="col-12 col-lg-10">
                  <p>Versione rilasciata</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-2">
                  <div
                    class="container"
                    style={{
                      marginTop: "7px",
                      display: "block",
                      width: 20,
                      height: 20,
                      borderRadius: 20 / 2,
                      backgroundColor: "red",
                      border: "2px solid black",
                    }}
                  ></div>
                </div>
                <div class="col-12 col-lg-10">
                  <p>Versione precedente</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-2">
                  <div
                    class="container"
                    style={{
                      marginTop: "7px",
                      display: "block",
                      width: 20,
                      height: 20,
                      borderRadius: 20 / 2,
                      backgroundColor: "black",
                      border: "2px solid black",
                    }}
                  ></div>
                </div>
                <div class="col-12 col-lg-10">
                  <p>Documento di supporto</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
