import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(

  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);






export const options = {
  responsive: true,
  scales: {
    x: { stacked: true },
    y: {
      beginAtZero: true
    }
  },
  plugins: {
    legend: {
      position: 'bottom'
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
};


export function App(dataBar) {
console.log("this is the data in the chart", dataBar.data?.expenses
//[60, 50, 40, 30, 20]
)
  const data = {
    labels: dataBar.data?.types,
    datasets: [{
      type: 'bar',
      label: 'Data',
      data: dataBar.data?.expenses,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: ['rgb(17, 47, 105)',
        'rgb(59, 148, 232)',
        'rgb(51, 204, 255)',
        'rgb(205, 220, 234)',
        'rgb(159, 172, 178)',
      ],
      categoryPercentage: 0.5
    }, {
      type: 'bar',
      label: 'Budget',
      data: dataBar.data?.budgets,
      fill: false,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderColor: 'rgb(201, 203, 207)',
      borderWidth: {
        top: 4
      },
      categoryPercentage: 0.5,
      //These don't work 
      datasetStrokeStyle: "dashed",
      borderStyle: "dashed"
      
      
    }]
  };


  return <Bar myData={dataBar} data={data} options={options} />;
}