import React from "react";

export default function ConsipFooter() {
  return (
    <footer className="it-footer" style={{ marginTop: "auto" }}>
      <div className="it-footer-main">
        <div className="container">
          <div className="row py-4">
            <div className="col-12 col-md-3 text-left pb-3 pb-md-0">
              Per maggiori informazioni contattare:
              <b> info@sicurezzapal-lotto2.it</b>
              <div>PEC: <b>dra@deloitte.legalmail.it</b></div>
            </div>
            <div className="col-6 col-md-3 text-left align-self-center" style={{paddingLeft:"6vw"}}>
              <img
                className="d-none d-md-block"
                src={process.env.PUBLIC_URL + "/assets/icons/Picture1.png"}
                height={30}
                alt="deloitte"
              />
              <img
                className="d-block d-md-none"
                src={process.env.PUBLIC_URL + "/assets/icons/Picture1.png"}
                height={30}
                alt="deloitte"
              />
            </div>
            <div className="col-6 col-md-3 text-left align-self-center" style={{paddingLeft:"9vw", paddingBottom: "1vw"}}>
              <img
                className="d-none d-md-block"
                src={process.env.PUBLIC_URL + "/assets/icons/Picture2.png"}
                height={50}
                alt="EY"
              />
              <img
                className="d-block d-md-none"
                src={process.env.PUBLIC_URL + "/assets/icons/Picture2.png"}
                height={30}
                alt="EY"
              />
            </div>
            <div className="col-6 col-md-3 text-left align-self-center" style={{paddingLeft:"6vw"}}>
              <img
                className="d-none d-md-block"
                src={process.env.PUBLIC_URL + "/assets/icons/Picture3.png"}
                height={50}
                alt="know"
              />
              <img
                className="d-block d-md-none"
                src={process.env.PUBLIC_URL + "/assets/icons/Picture3.png"}
                height={30}
                alt="know"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
